import Button from '@material-ui/core/Button';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';
import Typography from '@material-ui/core/Typography';
import BuildIcon from '@material-ui/icons/Build';
import MuiAlert from '@material-ui/lab/Alert';
import { useEffect, useState } from 'react';
import styles from '../styles/som-testing-section.module.scss';
import {
  checkIsAdminOrEngineer
} from "../util/util";
import SOMTestingStep1 from './SOMTestingStep1';
import SOMTestingStep2 from './SOMTestingStep2';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function SOMTestingSection(props) {
  const isAdminOrEngineer = checkIsAdminOrEngineer();

  // Parameters are needed by `SOMFirmwareTestingSection` but set by `SOMFirmwareSelectUploadSection`
  const [testingInputAbb, setTestingInputAbb] = useState(null);
  const [testingInputVersion, setTestingInputVersion] = useState(null);
  const [testingInputPackageId, setTestingInputPackageId] = useState(null);
  const [testingInputImageUrl, setTestingInputImageUrl] = useState(null);
  const [testingInputTimeoutSeconds, setTestingInputTimeoutSeconds] = useState(null);
  const [nextPageDelay, setNextPageDelay] = useState(0)

  // To control navigation buttons for the stepper
  const [isLoadingFirmwareSelectUploadSection, setIsLoadingFirmwareSelectUploadSection] = useState(false);
  const [isUploadingFirmware, setIsUploadingFirmware] = useState(false);
  const [isFirmwareUploadCompleted, setIsFirmwareUploadCompleted] = useState(false);
  const [isTestingFirmware, setIsTestingFirmware] = useState(false);
  const [isFirmwareTestingCompleted, setIsFirmwareTestingCompleted] = useState(false);
  const [isWebSerialPortOpen, setIsWebSerialPortOpen] = useState(false);

    const startCountdown = () => {
        const countdownInterval = setInterval(() => {
            setNextPageDelay((prevCountdown) => prevCountdown - 1);
        }, 1000)

        return () => {
            clearInterval(countdownInterval)
        }
    }
    useEffect(() => {
        if (nextPageDelay > 0) {
            const interval_id = window.setInterval(function(){}, Number.MAX_SAFE_INTEGER);
            // Clear any timeout/interval up to that id
            for (let i = 1; i < interval_id; i++) {
                window.clearInterval(i);
            }
            startCountdown()
        } else {
            
            const interval_id = window.setInterval(function(){}, Number.MAX_SAFE_INTEGER);
            // Clear any timeout/interval up to that id
            for (let i = 1; i < interval_id; i++) {
                window.clearInterval(i);
            }
        }
    
    }, [nextPageDelay])
  

  // Stepper UI
  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return <SOMTestingStep1
            setNextPageDelay={setNextPageDelay}
          setTestingInputAbb={setTestingInputAbb}
          setTestingInputVersion={setTestingInputVersion}
          setTestingInputPackageId={setTestingInputPackageId}
          setTestingInputImageUrl={setTestingInputImageUrl}
          setTestingInputTimeoutSeconds={setTestingInputTimeoutSeconds}

          setIsLoadingFirmwareSelectUploadSection={setIsLoadingFirmwareSelectUploadSection}
          setIsUploadingFirmware={setIsUploadingFirmware}
          setIsFirmwareUploadCompleted={setIsFirmwareUploadCompleted}

          isFirmwareUploadCompleted={isFirmwareUploadCompleted}
          isUploadingFirmware={isUploadingFirmware}

          isWebSerialPortOpen={isWebSerialPortOpen}
          setIsWebSerialPortOpen={setIsWebSerialPortOpen}
        />;
      case 1:
        return <SOMTestingStep2
          testingInputAbb={testingInputAbb}
          testingInputVersion={testingInputVersion}
          testingInputPackageId={testingInputPackageId}
          testingInputImageUrl={testingInputImageUrl}
          testingInputTimeoutSeconds={testingInputTimeoutSeconds}
          testingInputCommand="test"
          isTestingFirmware={isTestingFirmware}
          setIsTestingFirmware={setIsTestingFirmware}
          isFirmwareTestingCompleted={isFirmwareTestingCompleted}
          setIsFirmwareTestingCompleted={setIsFirmwareTestingCompleted}
          setIsWebSerialPortOpen={setIsWebSerialPortOpen}
        />;
      default:
        return 'Unknown step';
    }
  };

  const [activeStep, setActiveStep] = useState(0);
  const steps = ['Upload', 'Test'];

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    setIsFirmwareUploadCompleted(false);
    setIsFirmwareTestingCompleted(false);
  };

  const handleReset = () => {
    setActiveStep(0);
    setIsFirmwareUploadCompleted(false);
    setIsFirmwareTestingCompleted(false);
  };

  const isBackBtnDisabled = () => {

    if (!isAdminOrEngineer) {
      return true;
    }

    if (activeStep === 0) {
      return true;
    }

    if (activeStep === 1 && isWebSerialPortOpen) {
      return true;
    }

    return false;
  };

  const isFinishNextBtnDisabled = () => {
    if (isLoadingFirmwareSelectUploadSection) {
        return true;
    }

    if (isUploadingFirmware || isTestingFirmware) {
        return true;
    }

    if (activeStep === 1 && isWebSerialPortOpen) {
        return true;
    }

    if (isAdminOrEngineer) {
        return false;
    }

    if (activeStep === 0 && isFirmwareUploadCompleted) {
        return false;
    }

    if (activeStep === 1 && isFirmwareTestingCompleted) {
        return false;
    }
    if (nextPageDelay > 0) {
        return true
    }

    return true;
  };

  return (
    <section className={styles.mainSection}>
      <Typography variant="h4" component="h1" className={styles.heading}>
        <BuildIcon fontSize="large" />
        SOM Testing
      </Typography>
      <section>
        <Stepper activeStep={activeStep}>
          {steps.map((label, index) => {
            const stepProps = {};
            const labelProps = {};
            return (
              <Step key={label} {...stepProps}>
                <StepLabel {...labelProps}>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
        <section>
          {activeStep === steps.length ? (
            <section className={styles.allStepsCompleted}>
              <h2>
                All steps completed!
              </h2>
              <Button
                variant="contained"
                color="primary"
                onClick={handleReset}
              >
                Reset
              </Button>
            </section>
          ) : (
            <section>
              <section>
                {getStepContent(activeStep)}
              </section>
              <section className={styles.navBtns}>
                {isWebSerialPortOpen &&
                  <Alert severity="warning" className={styles.alert}>
                    Disconnect from Serial Port first before navigating.
                  </Alert>
                }
                <div>
                  <Button
                    disabled={isBackBtnDisabled()}
                    onClick={handleBack}
                  >
                    Back
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleNext}
                    disabled={isFinishNextBtnDisabled()}
                  >
                    {activeStep === steps.length - 1 ? 'Finish' : (isAdminOrEngineer ? "NEXT": (nextPageDelay > 0 ? `NEXT (${nextPageDelay})` : "NEXT") ) }
                  </Button>
                </div>
              </section>
            </section>
          )}
        </section>
      </section>
    </section >
  );
};