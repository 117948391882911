import Box from "@material-ui/core/Box";
import Alert from "@material-ui/lab/Alert";
import React, { useState } from "react";
import { useRouter } from "./../util/router";
import AuthForm from "./AuthForm";

function Auth({
  afterAuthPath,
  type,
  buttonAction
}) {
  const router = useRouter();
  const [formAlert, setFormAlert] = useState(null);

  const handleAuth = () => {
    router.push(afterAuthPath);
  };

  const handleFormAlert = (data) => {
    setFormAlert(data);
  };

  return (
    <>
      {formAlert && (
        <Box mb={3}>
          <Alert severity={formAlert.type}>{formAlert.message}</Alert>
        </Box>
      )}

      <AuthForm
        type={type}
        buttonAction={buttonAction}
        onAuth={handleAuth}
        onFormAlert={handleFormAlert}
      />
    </>
  );
}

export default Auth;
