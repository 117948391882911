import React from "react";
import Meta from "../components/Meta";
import ApplicationTestingSection from "../components/ApplicationTestingSection";
import { requireAuth } from "../util/auth";

function ApplicationTestingPage(props) {
  return (
    <>
      <Meta title="Application Testing" />
      <ApplicationTestingSection />
    </>
  );
}

export default requireAuth(ApplicationTestingPage);
