import React, { useState } from 'react';
import styles from '../styles/som-testing-step-1.module.scss';
import {
  API_PATH_GET_SOM_TESTING_INFO_V1_1,
  UPLOAD_TYPE
} from "../util/constants";
import FirmwareSelect from './FirmwareSelect';
import FirmwareUpload from './FirmwareUpload';

export default function SOMTestingStep1({
    setNextPageDelay,
  setTestingInputAbb,
  setTestingInputVersion,
  setTestingInputPackageId,
  setTestingInputImageUrl,
  setTestingInputTimeoutSeconds,
  setIsLoadingFirmwareSelectUploadSection,
  setIsUploadingFirmware,
  setIsFirmwareUploadCompleted,
  isFirmwareUploadCompleted,
  isUploadingFirmware,
  isWebSerialPortOpen,
  setIsWebSerialPortOpen
}) {
  // Parameters are needed by `SOMFirmwareUpload` but they are set by `SOMFirmwareSelect`
  const [selectedBoardName, setSelectedBoardName] = useState(null);
  const [selectedFirmwareSet, setSelectedFirmwareSet] = useState(null);

  // Manage these states here because SOMFirmwareSelect can call `resetFirmwareUploadSection`
  const [firmwareUploadMessage, setFirmwareUploadMessage] = useState("");
  const [firmwareFileNamesMessage, setFirmwareFileNamesMessage] = useState("");
  const [firmwareUploadStdout, setFirmwareUploadStdout] = useState("");
  const [currentUploadOrder, setCurrentUploadOrder] = useState(1);
  const [isFirmwareUploadSuccessful, setIsFirmwareUploadSuccessful] = useState(false);
  const [firmwareUploadSuccessMessage, setFirmwareUploadSuccessMessage] = useState("");
  const [isFirmwareUploadFailed, setIsFirmwareUploadFailed] = useState(false);
  const [firmwareUploadFailedMessage, setFirmwareUploadFailedMessage] = useState("");

  const resetFirmwareUploadSection = () => {
    setFirmwareUploadMessage("");
    setFirmwareFileNamesMessage("");
    setFirmwareUploadStdout("");
    setCurrentUploadOrder(1);

    setIsFirmwareUploadSuccessful(false);
    setFirmwareUploadSuccessMessage("");
    setIsFirmwareUploadFailed(false);
    setFirmwareUploadFailedMessage("");

    setIsFirmwareUploadCompleted(false);
  };

  return (
    <section className={styles.mainSection}>
      <FirmwareSelect
        testingType={UPLOAD_TYPE.SOM}
        apiPathForTestingInfo={API_PATH_GET_SOM_TESTING_INFO_V1_1}
        setTestingInputAbb={setTestingInputAbb}
        setNextPageDelay={setNextPageDelay}
        setTestingInputVersion={setTestingInputVersion}
        setTestingInputPackageId={setTestingInputPackageId}
        setTestingInputImageUrl={setTestingInputImageUrl}
        setTestingInputTimeoutSeconds={setTestingInputTimeoutSeconds}
        setIsLoadingFirmwareSelectUploadSection={setIsLoadingFirmwareSelectUploadSection}
        resetFirmwareUploadSection={resetFirmwareUploadSection}
        setSelectedBoardName={setSelectedBoardName}
        setSelectedFirmwareSet={setSelectedFirmwareSet}
        isUploadingFirmware={isUploadingFirmware}
        setIsSingleMcuid={null}
        setSelectedCheckMcuidFirmwareSet={null}
        setCheckMcuidAbb={null}
        setCheckMcuidCommand={null}
      />
      <FirmwareUpload
        uploadType={UPLOAD_TYPE.SOM}
        selectedBoardName={selectedBoardName}
        selectedFirmwareSet={selectedFirmwareSet}
        isUploadingFirmware={isUploadingFirmware}
        setIsUploadingFirmware={setIsUploadingFirmware}
        isFirmwareUploadCompleted={isFirmwareUploadCompleted}
        setIsFirmwareUploadCompleted={setIsFirmwareUploadCompleted}
        firmwareUploadMessage={firmwareUploadMessage}
        setFirmwareUploadMessage={setFirmwareUploadMessage}
        firmwareFileNamesMessage={firmwareFileNamesMessage}
        setFirmwareFileNamesMessage={setFirmwareFileNamesMessage}
        firmwareUploadStdout={firmwareUploadStdout}
        setFirmwareUploadStdout={setFirmwareUploadStdout}
        currentUploadOrder={currentUploadOrder}
        setCurrentUploadOrder={setCurrentUploadOrder}
        isFirmwareUploadSuccessful={isFirmwareUploadSuccessful}
        setIsFirmwareUploadSuccessful={setIsFirmwareUploadSuccessful}
        firmwareUploadSuccessMessage={firmwareUploadSuccessMessage}
        setFirmwareUploadSuccessMessage={setFirmwareUploadSuccessMessage}
        isFirmwareUploadFailed={isFirmwareUploadFailed}
        setIsFirmwareUploadFailed={setIsFirmwareUploadFailed}
        firmwareUploadFailedMessage={firmwareUploadFailedMessage}
        setFirmwareUploadFailedMessage={setFirmwareUploadFailedMessage}
        isWebSerialPortOpen={isWebSerialPortOpen}
        selectedCheckMcuidFirmwareSet={null}
        targetUploadOrder={null}
        setTargetUploadOrder={null}
        isSingleMcuid={null}
        setIsMcuidTestingDisabled={null}
      />
    </section >
  );
}