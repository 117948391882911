import React, { useState } from 'react';
import styles from '../styles/application-testing-step-1.module.scss';
import FirmwareSelect from './FirmwareSelect';
import FirmwareUpload from './FirmwareUpload';
import FirmwareTest from './FirmwareTest';
import {
  API_PATH_GET_APP_TESTING_INFO_V1_0,
  TESTING_TYPE,
  UPLOAD_TYPE
} from "../util/constants";

export default function ApplicationTestingStep1({
  setTestingInputAbb,
  setTestingInputVersion,
  setTestingInputPackageId,
  setTestingInputImageUrl,
  setTestingInputTimeoutSeconds,
  setIsLoadingFirmwareSelectUploadSection,
  setIsUploadingFirmware,
  setIsFirmwareUploadCompleted,
  isFirmwareUploadCompleted,
  isUploadingFirmware,
  setTestingInputMcuid,
  setTestingInputSomIds,
  testingInputMcuid,
  isWebSerialPortOpen,
  setIsWebSerialPortOpen
}) {
  // Parameters are needed by `SOMFirmwareUpload` but they are set by `SOMFirmwareSelect`
  const [selectedBoardName, setSelectedBoardName] = useState(null);
  const [selectedFirmwareSet, setSelectedFirmwareSet] = useState(null);
  const [selectedCheckMcuidFirmwareSet, setSelectedCheckMcuidFirmwareSet] = useState(null);

  // Manage these states here because SOMFirmwareSelect can call `resetFirmwareUploadSection`
  const [firmwareUploadMessage, setFirmwareUploadMessage] = useState("");
  const [firmwareFileNamesMessage, setFirmwareFileNamesMessage] = useState("");
  const [firmwareUploadStdout, setFirmwareUploadStdout] = useState("");
  const [currentUploadOrder, setCurrentUploadOrder] = useState(1);
  const [isFirmwareUploadSuccessful, setIsFirmwareUploadSuccessful] = useState(false);
  const [firmwareUploadSuccessMessage, setFirmwareUploadSuccessMessage] = useState("");
  const [isFirmwareUploadFailed, setIsFirmwareUploadFailed] = useState(false);
  const [firmwareUploadFailedMessage, setFirmwareUploadFailedMessage] = useState("");

  const [isSingleMcuid, setIsSingleMcuid] = useState(null);
  const [checkMcuidAbb, setCheckMcuidAbb] = useState(null);
  const [checkMcuidCommand, setCheckMcuidCommand] = useState(null);

  const [isTestingFirmware, setIsTestingFirmware] = useState(false);
  const [isFirmwareTestingCompleted, setIsFirmwareTestingCompleted] = useState(false);

  const [targetUploadOrder, setTargetUploadOrder] = useState(1);

  const [isMcuidChecked, setIsMcuidChecked] = useState(false);
  const [isMcuidFirmwareUploaded, setIsMcuidFirmwareUploaded] = useState(false);

  const [isMcuidTestingDisabled, setIsMcuidTestingDisabled] = useState(false);

  const resetFirmwareUploadSection = () => {
    setFirmwareUploadMessage("");
    setFirmwareFileNamesMessage("");
    setFirmwareUploadStdout("");
    setCurrentUploadOrder(1);

    setIsFirmwareUploadSuccessful(false);
    setFirmwareUploadSuccessMessage("");
    setIsFirmwareUploadFailed(false);
    setFirmwareUploadFailedMessage("");

    setIsFirmwareUploadCompleted(false);
  };

  return (
    <section className={styles.mainSection}>
      <FirmwareSelect
        setSelectedBoardName={setSelectedBoardName}
        setSelectedFirmwareSet={setSelectedFirmwareSet}

        setTestingInputAbb={setTestingInputAbb}
        setTestingInputVersion={setTestingInputVersion}
        setTestingInputPackageId={setTestingInputPackageId}
        setTestingInputImageUrl={setTestingInputImageUrl}
        setTestingInputTimeoutSeconds={setTestingInputTimeoutSeconds}

        setIsLoadingFirmwareSelectUploadSection={setIsLoadingFirmwareSelectUploadSection}

        resetFirmwareUploadSection={resetFirmwareUploadSection}

        isUploadingFirmware={isUploadingFirmware}

        apiPathForTestingInfo={API_PATH_GET_APP_TESTING_INFO_V1_0}
        testingType={TESTING_TYPE.APP}
        setIsSingleMcuid={setIsSingleMcuid}

        setSelectedCheckMcuidFirmwareSet={setSelectedCheckMcuidFirmwareSet}

        setCheckMcuidAbb={setCheckMcuidAbb}
        setCheckMcuidCommand={setCheckMcuidCommand}
      />
      <FirmwareUpload
        selectedBoardName={selectedBoardName}
        selectedFirmwareSet={selectedFirmwareSet}

        isUploadingFirmware={isUploadingFirmware}
        setIsUploadingFirmware={setIsUploadingFirmware}

        isFirmwareUploadCompleted={isFirmwareUploadCompleted}
        setIsFirmwareUploadCompleted={setIsFirmwareUploadCompleted}

        firmwareUploadMessage={firmwareUploadMessage}
        setFirmwareUploadMessage={setFirmwareUploadMessage}

        firmwareFileNamesMessage={firmwareFileNamesMessage}
        setFirmwareFileNamesMessage={setFirmwareFileNamesMessage}

        firmwareUploadStdout={firmwareUploadStdout}
        setFirmwareUploadStdout={setFirmwareUploadStdout}

        currentUploadOrder={currentUploadOrder}
        setCurrentUploadOrder={setCurrentUploadOrder}

        isFirmwareUploadSuccessful={isFirmwareUploadSuccessful}
        setIsFirmwareUploadSuccessful={setIsFirmwareUploadSuccessful}

        firmwareUploadSuccessMessage={firmwareUploadSuccessMessage}
        setFirmwareUploadSuccessMessage={setFirmwareUploadSuccessMessage}

        isFirmwareUploadFailed={isFirmwareUploadFailed}
        setIsFirmwareUploadFailed={setIsFirmwareUploadFailed}

        firmwareUploadFailedMessage={firmwareUploadFailedMessage}
        setFirmwareUploadFailedMessage={setFirmwareUploadFailedMessage}

        uploadType={UPLOAD_TYPE.CHECK_MCUID}
        selectedCheckMcuidFirmwareSet={selectedCheckMcuidFirmwareSet}

        targetUploadOrder={null}
        setTargetUploadOrder={setTargetUploadOrder}
        isSingleMcuid={isSingleMcuid}

        isWebSerialPortOpen={isWebSerialPortOpen}

        isMcuidChecked={isMcuidChecked}
        setIsMcuidChecked={setIsMcuidChecked}

        setIsMcuidFirmwareUploaded={setIsMcuidFirmwareUploaded}
        setIsMcuidTestingDisabled={setIsMcuidTestingDisabled}
      />
      <FirmwareTest
        testingType={TESTING_TYPE.CHECK_MCUID}
  
        testingInputAbb={checkMcuidAbb}
        testingInputCommand={checkMcuidCommand}
        testingInputVersion={null}
        testingInputPackageId={null}
        testingInputImageUrl={null}
        testingInputTimeoutSeconds={3}

        testingInputMcuid={testingInputMcuid}
        setTestingInputMcuid={setTestingInputMcuid}
        setTestingInputSomIds={setTestingInputSomIds}

        isTestingFirmware={isTestingFirmware}
        setIsTestingFirmware={setIsTestingFirmware}

        isFirmwareTestingCompleted={isFirmwareTestingCompleted}
        setIsFirmwareTestingCompleted={setIsFirmwareTestingCompleted}
        
        isUploadingFirmware={isUploadingFirmware}
        
        setIsWebSerialPortOpen={setIsWebSerialPortOpen}

        setIsMcuidChecked={setIsMcuidChecked}
        isMcuidFirmwareUploaded={isMcuidFirmwareUploaded}

        isMcuidTestingDisabled={isMcuidTestingDisabled}
      />
      <FirmwareUpload
      selectedBoardName={selectedBoardName}
      selectedFirmwareSet={selectedFirmwareSet}
      
      isUploadingFirmware={isUploadingFirmware}
      setIsUploadingFirmware={setIsUploadingFirmware}
      
      isFirmwareUploadCompleted={isFirmwareUploadCompleted}
      setIsFirmwareUploadCompleted={setIsFirmwareUploadCompleted}
      
      firmwareUploadMessage={firmwareUploadMessage}
      setFirmwareUploadMessage={setFirmwareUploadMessage}
      
      firmwareFileNamesMessage={firmwareFileNamesMessage}
      setFirmwareFileNamesMessage={setFirmwareFileNamesMessage}
      
      firmwareUploadStdout={firmwareUploadStdout}
      setFirmwareUploadStdout={setFirmwareUploadStdout}
      
      currentUploadOrder={currentUploadOrder}
      setCurrentUploadOrder={setCurrentUploadOrder}
      
      isFirmwareUploadSuccessful={isFirmwareUploadSuccessful}
      setIsFirmwareUploadSuccessful={setIsFirmwareUploadSuccessful}
      
      firmwareUploadSuccessMessage={firmwareUploadSuccessMessage}
      setFirmwareUploadSuccessMessage={setFirmwareUploadSuccessMessage}
      
      isFirmwareUploadFailed={isFirmwareUploadFailed}
      setIsFirmwareUploadFailed={setIsFirmwareUploadFailed}
      
      firmwareUploadFailedMessage={firmwareUploadFailedMessage}
      setFirmwareUploadFailedMessage={setFirmwareUploadFailedMessage}
      
      uploadType={UPLOAD_TYPE.APP}
      selectedCheckMcuidFirmwareSet={selectedCheckMcuidFirmwareSet}
      
      targetUploadOrder={targetUploadOrder}
      setTargetUploadOrder={setTargetUploadOrder}
      isSingleMcuid={isSingleMcuid}
      
      isWebSerialPortOpen={isWebSerialPortOpen}

      isMcuidChecked={isMcuidChecked}
      setIsMcuidChecked={setIsMcuidChecked}

      setIsMcuidFirmwareUploaded={setIsMcuidFirmwareUploaded}
      setIsMcuidTestingDisabled={setIsMcuidTestingDisabled}
      />
    </section >
  );
}