import React, { useEffect, useState } from 'react';
import styles from '../styles/debug-section.module.scss';
import { useAuth } from "../util/auth";

function DebugSection(props) {
  const auth = useAuth();

  const BAUD_RATE = 115200;

  const [isWebSerialAvailable, setIsWebSerialAvailable] = useState(false);

  const [connectedPort, setConnectedPort] = useState(null);
  const [connectedPortReader, setConnectedPortReader] = useState(null);
  const [isPortOpen, setIsPortOpen] = useState(false);
  const [isPortListening, setIsPortListening] = useState(false);

  const [serialPayloadSent, setSerialPayloadSent] = useState(null);
  const [serialPayloadReceived, setSerialPayloadReceived] = useState(null);

  const [customPayload, setCustomPayload] = useState(
    JSON.stringify(
      {
        abb: "translator",
        version: "2.1",
        command: "test",
      },
      null,
      4
    )
  );

  useEffect(() => {
    if (navigator.serial) {
      console.log("[INFO] Web Serial API is available.");
      setIsWebSerialAvailable(true);
    } else {
      console.log("[ERROR] - Web Serial API is unavailable.");
      setIsWebSerialAvailable(false);
    }
  }, []);

  useEffect(() => {
    if (connectedPort) {
      handleOpenPort();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connectedPort]);

  useEffect(() => {
    if (isPortOpen) {
      handleListen();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPortOpen]);

  const handleRequestPort = () => {
    navigator.serial
      .requestPort()
      .then((port) => {
        setConnectedPort(port);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handleOpenPort = async () => {
    if (connectedPort) {
      console.log("[INFO] Opening port...");
      await connectedPort.open({ baudRate: BAUD_RATE });
      setIsPortOpen(true);
    }
  };

  const convertCharCodeToString = (array) => {
    let buffer = "";

    for (let i = 0; i < array.length; i++) {
      buffer += String.fromCharCode(array[i]);
    }
    return buffer;
  };

  const handleListen = async () => {
    if (connectedPort) {
      const reader = connectedPort.readable.getReader();
      setConnectedPortReader(reader);

      // Listen to data coming from the serial device.
      setIsPortListening(true);

      let buffer = [];
      let isFragmented = false;

      try {
        while (true) {
          const { value, done } = await reader.read();
          const lastChar = value[value.length - 1];

          // https://ascii.cl/
          // ASCII 10 is new line character: LF (Line Feed) or \n
          if (lastChar !== 10) {
            // initial or intermediate fragment detected
            isFragmented = true;
            buffer = [...buffer, ...value];
          } else if (lastChar === 10 && isFragmented) {
            // final fragment detected
            buffer = [...buffer, ...value];

            const response = convertCharCodeToString(buffer);

            try {
              const parsedResponse = JSON.parse(response);
              console.log(`[SUCCESS] Payload received: ${JSON.stringify(parsedResponse)}`);

              setSerialPayloadReceived(parsedResponse);

            } catch (error) {
              console.log("[ERROR] - Unable to parse response.");
              console.log(error.message);
              console.log(`[ERROR] - Received response: ${response}`);
            }

            // reset
            isFragmented = false;
            buffer.length = 0;
          } else {
            // no fragment
            const response = convertCharCodeToString(value);

            console.log(`[INFO] Payload received: ${response}`);
          }

          if (done) {
            // Allow the serial port to be closed later.
            reader.releaseLock();
            setIsPortListening(false);
            break;
          }
        }
      } catch (error) {
        // https://developer.chrome.com/en/articles/serial/#close-port
        // calling reader.cancel() will force reader.read() to resolve immediately with { value: undefined, done: true } and therefore allowing the loop to call reader.releaseLock()
        if (error.message === "Cannot read properties of undefined (reading 'length')") {
          console.log("[INFO] Reader is cancelled.");
        } else {
          console.log(error.message);
        }
      } finally {
        // Allow the serial port to be closed later.
        console.log("[INFO] Releasing lock on reader.");
        reader.releaseLock();
        setIsPortListening(false);
      }

    } else {
      console.log("[ERROR] - No connected port.");
    }
  };

  const writeToSerialPort = async (payload) => {
    setSerialPayloadSent(null);
    setSerialPayloadReceived(null);
    const writer = connectedPort.writable.getWriter();

    const jsonPayload = JSON.stringify(payload);
    setSerialPayloadSent(payload);

    const string = `${jsonPayload}\r`;
    const uint8Array = new Uint8Array(string.length);

    for (let i = 0; i < string.length; i++) {
      uint8Array[i] = string.charCodeAt(i);
    }

    const data = uint8Array;
    await writer.write(data);
    console.log(`[INFO] Payload sent: ${jsonPayload}`);

    // Allow the serial port to be closed later.
    writer.releaseLock();
  };

  const handleSendCustomPayload = async () => {
    const payload = JSON.parse(customPayload);

    writeToSerialPort(payload);
  };


  const PrettyPrintJson = ({ data }) => (
    <div>
      <pre>{JSON.stringify(data, null, 2)}</pre>
    </div>
  );

  const handleManualPayloadChange = (event) => {
    setCustomPayload(event.target.value);
  };

  const handleDisconnect = async () => {
    if (connectedPort && connectedPortReader) {
      await connectedPortReader.cancel();
      console.log("[INFO] Closing port...");
      await connectedPort.close();

      // reset all states
      setConnectedPort(null);
      setConnectedPortReader(null);
      setIsPortOpen(false);
      setIsPortListening(false);
      setSerialPayloadSent(null);
      setSerialPayloadReceived(null);
    }
  };

  return (
    <section className={styles.mainSection}>
      <h1>Welcome {auth.user.username}!</h1>
      <p className={styles.disclaimer}>This is for local testing, no data is sent to remote backend.</p>
      <section>
        <h3>Browser Status</h3>
        <ul>
          <li>
            Serial port available: {isWebSerialAvailable ? "✅" : "🚫"}
          </li>
          {
            !isWebSerialAvailable &&
            <li>
              Please use Google Chrome browser.
            </li>
          }
        </ul>
      </section>
      {isWebSerialAvailable && (
        <>
          <hr />
          <section>
            <h3>Serial Port Status</h3>
            {isWebSerialAvailable && (
              <ul>
                <li>Connected: {connectedPort ? "✅" : "🚫"}</li>
                <li>Open: {isPortOpen ? "✅" : "🚫"}</li>
                <li>Listening: {isPortListening ? "✅" : "🚫"}</li>
              </ul>
            )}
          </section>
          <hr />
          <section>
            <h3>Serial Port Interactions</h3>
            <p>
              <button
                onClick={handleRequestPort}
                disabled={connectedPort ? true : false}
              >
                Connect to Serial Port
              </button>
            </p>
            <p>
              <button
                onClick={handleDisconnect}
                disabled={isPortListening ? false : true}
              >
                Disconnect from Serial Port
              </button>
            </p>
          </section>

          {isPortListening && (
            <>
              <hr />
              <section>
                <h3>Microcontroller Interactions</h3>
                <section>
                  <h4>Custom JSON payload</h4>
                  <p>
                    <textarea
                      value={customPayload}
                      onChange={handleManualPayloadChange}
                      rows="5"
                      style={{ height: "auto", width: "100%" }}
                    />
                  </p>
                  <p>
                    <button onClick={handleSendCustomPayload}>
                      Send custom payload
                    </button>
                  </p>
                </section>
                <hr />
                <section>
                  <h4>Payload info</h4>
                  <p>⬆️ Payload sent:</p>
                  <article>
                    {serialPayloadSent ? (
                      <PrettyPrintJson data={serialPayloadSent} />
                    ) : (
                      "---"
                    )}
                  </article>
                  <p>⬇️ Payload received:</p>
                  <article>
                    {serialPayloadReceived ? (
                      <PrettyPrintJson data={serialPayloadReceived} />
                    ) : (
                      "---"
                    )}
                  </article>
                </section>
              </section>
            </>
          )}
        </>
      )}
    </section>
  );
}

export default DebugSection;
