import React from "react";
import Meta from "../components/Meta";
import ProductionFirmwareUploadSection from "../components/ProductionFirmwareUploadSection";
import { requireAuth } from "../util/auth";

function ProductionFirmwareUploadPage(props) {
  return (
    <>
      <Meta title="Production Firmware Upload" />
      <ProductionFirmwareUploadSection />
    </>
  );
}

export default requireAuth(ProductionFirmwareUploadPage);
