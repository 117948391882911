import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useAuth } from "./../util/auth";

function AuthForm({
  type,
  buttonAction,
  onAuth,
  onFormAlert
}) {
  const auth = useAuth();
  const [pending, setPending] = useState(false);
  const { handleSubmit, register, errors } = useForm();

  const submitHandlersByType = {
    signin: ({ username, pass }) => {
      return auth.signin(username, pass).then(() => {
        // Call auth complete handler
        onAuth();
      });
    },
  };

  // Handle form submission
  const onSubmit = ({ username, pass }) => {
    // Show pending indicator
    setPending(true);

    // Call submit handler for auth type
    submitHandlersByType[type]({
      username,
      pass,
    }).catch((error) => {
      setPending(false);
      // Show error alert message
      onFormAlert({
        type: "error",
        message: error.message,
      });
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container={true} spacing={2}>
        <Grid item={true} xs={12}>
          <TextField
            variant="outlined"
            type="text"
            label="Username"
            name="username"
            autoComplete="username"
            placeholder="username"
            error={errors.username ? true : false}
            helperText={errors.username && errors.username.message}
            fullWidth={true}
            inputRef={register({
              required: "Please enter your username",
            })}
          />
        </Grid>
        <Grid item={true} xs={12}>
          <TextField
            variant="outlined"
            type="password"
            label="Password"
            autoComplete="current-password"
            name="pass"
            error={errors.pass ? true : false}
            helperText={errors.pass && errors.pass.message}
            fullWidth={true}
            inputRef={register({
              required: "Please enter your password",
            })}
          />
        </Grid>
        <Grid item={true} xs={12}>
          <Button
            variant="contained"
            color="primary"
            size="large"
            type="submit"
            disabled={pending}
            fullWidth={true}
          >
            {!pending && <span>{buttonAction}</span>}

            {pending && <CircularProgress size={28} />}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
}

export default AuthForm;
