import React, {
  createContext, useContext, useEffect, useState
} from "react";
import {
  API_PATH_JWT_TOKEN,
  API_PATH_GET_SOM_TESTING_INFO_V1_1,
  LS_KEY_JWT_ACCESS_TOKEN,
  LS_KEY_ROLE,
  LS_KEY_SOM_TESTING_INFO_JSON,
  LS_KEY_SOM_TESTING_INFO_RETRIEVED_TIMESTAMP,
  LS_KEY_SOM_TESTING_INFO_EXPIRY_TIMESTAMP,
  LS_KEY_APP_TESTING_INFO_JSON,
  LS_KEY_APP_TESTING_INFO_RETRIEVED_TIMESTAMP,
  LS_KEY_APP_TESTING_INFO_EXPIRY_TIMESTAMP,
  ALLOWED_ROLES
} from "../util/constants";
import PageLoader from "./../components/PageLoader";
import { history } from "./router";
import { apiRequest } from "./util";
import jwt_decode from "jwt-decode";

// Create a `useAuth` hook and `AuthProvider` that enables
// any component to subscribe to auth and re-render when it changes.
const authContext = createContext();
export const useAuth = () => useContext(authContext);
// This should wrap the app in `src/pages/_app.js`
export function AuthProvider({ children }) {
  const auth = useAuthProvider();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

// Hook that creates the `auth` object and handles state
// This is called from `AuthProvider` above (extracted out for readability)
function useAuthProvider() {
  // Store auth user in state
  // `user` will be object, `null` (loading) or `false` (logged out)
  const [user, setUser] = useState(null);

  const signin = async (username, password) => {
    const { jwtAccessToken, message } = await apiRequest(
      API_PATH_JWT_TOKEN,
      "POST",
      { username, password },
      "?format=json"
    )
      .then((data) => {
        if (data.access) {
          const { access: jwtAccessToken, role } = data;

          // if role is not one of the strings in ALLOWED_ROLES array then call the signout function
          if (!ALLOWED_ROLES.includes(role)) {
            signout();
            return {
              jwtAccessToken: null,
              message: "You are not authorized to access this page."
            };
          }


          const { username: usernameDecodedFromToken } = jwt_decode(jwtAccessToken);
          setUser({ username: usernameDecodedFromToken });
          localStorage.setItem(LS_KEY_JWT_ACCESS_TOKEN, jwtAccessToken);
          localStorage.setItem(LS_KEY_ROLE, role);
          return {
            jwtAccessToken,
            message: "success"
          };
        } else {
          return {
            jwtAccessToken: null,
            message: data.detail
          };
        }
      })
      .catch((err) => {
        console.log(err);
      });

    if (!jwtAccessToken) {
      throw new Error(message);
    }
  };

  const signout = () => {
    localStorage.removeItem(LS_KEY_JWT_ACCESS_TOKEN);
    localStorage.removeItem(LS_KEY_ROLE);
    localStorage.removeItem(LS_KEY_SOM_TESTING_INFO_JSON);
    localStorage.removeItem(LS_KEY_SOM_TESTING_INFO_RETRIEVED_TIMESTAMP);
    localStorage.removeItem(LS_KEY_SOM_TESTING_INFO_EXPIRY_TIMESTAMP);
    localStorage.removeItem(LS_KEY_APP_TESTING_INFO_JSON);
    localStorage.removeItem(LS_KEY_APP_TESTING_INFO_RETRIEVED_TIMESTAMP);
    localStorage.removeItem(LS_KEY_APP_TESTING_INFO_EXPIRY_TIMESTAMP);
    setUser(false);
  };

  useEffect(() => {
    const token = localStorage.getItem(LS_KEY_JWT_ACCESS_TOKEN);

    if (token) {
      // use the PBCA info API to check if the token is valid
      apiRequest(API_PATH_GET_SOM_TESTING_INFO_V1_1)
        .then((data) => {
          const { component } = data;
          if (component) {
            const { username: usernameDecodedFromToken } = jwt_decode(token);
            setUser({ username: usernameDecodedFromToken });
          } else {
            signout();
          }
        })
        .catch((err) => {
          console.log(err);
          signout();
        });
    } else {
      signout();
    }
  }, []);

  return {
    user,
    signin,
    signout
  };
}


// A Higher Order Component for requiring authentication
export const requireAuth = (Component) => {
  return function RequireAuthHOC(props) {
    // Get authenticated user
    const auth = useAuth();

    useEffect(() => {
      // Redirect if not signed in
      if (auth.user === false) {
        history.replace("/auth/signin");
      }
    }, [auth]);

    // Show loading indicator
    // We're either loading (user is `null`) or about to redirect from above `useEffect` (user is `false`)
    if (!auth.user) {
      return <PageLoader />;
    }

    // Render component now that we have user
    return <Component {...props} />;
  };
};
