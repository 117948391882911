export const AGENT_BASE_URL = "http://localhost:8000";
export const AGENT_REQUIRED_VERSION = "1.2.5";
export const AGENT_PATH_AGENT_STATUS = "agent-status";
export const AGENT_PATH_AGENT_VERSION = "agent-version";
export const AGENT_PATH_UPLOAD_FIRMWARE = "upload-firmware";
export const AGENT_PATH_SERIAL_PORTS = "get-ports";
export const AGENT_PATH_ARDUINO_CLI_STATUS = "arduino-cli-status";
export const ALLOWED_ROLES = ["admin", "engineer", "technician", "vendor-pcba"];
export const ALLOWED_DEBUG_ROLES = ["admin", "engineer"];
export const API_BASE_URL = process.env.REACT_APP_API_URL;
export const API_PATH_GET_SOM_TESTING_INFO_V1_1 = "internal/testing/v1.1/page/pcba/som";
export const API_PATH_GET_APP_TESTING_INFO_V1_0 = "internal/production/v1.0/page/pcba/application";
export const API_PATH_GET_ALL_FIRMWARE_LIST_V1_0 = "internal/production/v1.0/page/firmware";
export const API_PATH_JWT_TOKEN = "token";
export const API_PATH_POST_SOM_TEST_RESULTS = "internal/operations/v1.0/pcba/som";
export const API_PATH_POST_APP_TEST_RESULTS = "internal/operations/v1.0/pcba/application";
export const API_PATH_CHECK_MCUID = "internal/operations/v1.0/check/som/mcuid";
export const BAUD_RATE = 115200;
export const LS_KEY_JWT_ACCESS_TOKEN = "cloudatik-access";
export const LS_KEY_ROLE = "cloudatik-role";
export const LS_KEY_SOM_TESTING_INFO_JSON = "cloudatik-som-testing-json";
export const LS_KEY_SOM_TESTING_INFO_RETRIEVED_TIMESTAMP = "cloudatik-som-testing-retrieved-datetime";
export const LS_KEY_SOM_TESTING_INFO_EXPIRY_TIMESTAMP = "cloudatik-som-testing-expiry-datetime";
export const LS_KEY_APP_TESTING_INFO_JSON = "cloudatik-app-testing-json";
export const LS_KEY_APP_TESTING_INFO_RETRIEVED_TIMESTAMP = "cloudatik-app-testing-retrieved-datetime";
export const LS_KEY_APP_TESTING_INFO_EXPIRY_TIMESTAMP = "cloudatik-app-testing-expiry-datetime";
export const DOWNLOAD_URL_ARDUINO_CLI = "https://cloudatik-public.s3.ap-southeast-1.amazonaws.com/arduino-cli_nightly-20230414_Windows_64bit.msi";
export const DOWNLOAD_URL_CLOUDATIK_AGENT = `https://cloudatik-public.s3.ap-southeast-1.amazonaws.com/cloudatik-pcba-agent-${AGENT_REQUIRED_VERSION}.Setup.exe`;
export const COUNTDOWN_AUTO_SUBMIT_TEST_RESULT = 60 * 1000;
export const TESTING_TYPE = {
  SOM: "SOM",
  APP: "APP",
  CHECK_MCUID: "CHECK_MCUID",
};
export const UPLOAD_TYPE = {
  SOM: "SOM",
  APP: "APP",
  CHECK_MCUID: "CHECK_MCUID",
};
export const LS_KEY_TESTING_INFO = {
  SOM: "cloudatik-som-testing-json",
  APP: "cloudatik-app-testing-json"
};
export const IS_DEBUG_MODE = false;
