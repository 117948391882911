import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import RefreshIcon from '@material-ui/icons/Refresh';
import Alert from '@material-ui/lab/Alert';
import Skeleton from '@material-ui/lab/Skeleton';
import PropTypes from "prop-types";
import React, { useEffect, useState } from 'react';
import styles from '../styles/firmware-select.module.scss';
import {
  LS_KEY_JWT_ACCESS_TOKEN,
  LS_KEY_TESTING_INFO,
  TESTING_TYPE
} from "../util/constants";
import {
  apiRequest,
  cleanUpData
} from "../util/util";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));

export default function FirmwareSelect({
  testingType,
  apiPathForTestingInfo,
  setTestingInputAbb,
  setTestingInputVersion,
  setTestingInputPackageId,
  setTestingInputImageUrl,
  setTestingInputTimeoutSeconds,
  setIsLoadingFirmwareSelectUploadSection,
  resetFirmwareUploadSection,
  setSelectedBoardName,
  setSelectedFirmwareSet,
  isUploadingFirmware,
  setIsSingleMcuid,
  setSelectedCheckMcuidFirmwareSet,
  setCheckMcuidAbb,
  setCheckMcuidCommand,
  setNextPageDelay,
}) {
  const classes = useStyles();

  const jwtToken = localStorage.getItem(LS_KEY_JWT_ACCESS_TOKEN);

  const [availableComponents, setAvailableComponents] = useState(null);
  const [selectedComponent, setSelectedComponent] = useState(null);

  const [availableBoards, setAvailableBoards] = useState(null);
  const [selectedBoard, setSelectedBoard] = useState(null);

  const [availablePackages, setAvailablePackages] = useState(null);
  const [selectedPackage, setSelectedPackage] = useState(null);

  const [isFetchingData, setIsFetchingData] = useState(false);

  const [infoRetrievedDatetime, setInfoRetrievedTimestamp] = useState(null);

  /* Fetch data from backend API. */

  const setDefaultComponentValues = (targetComponentAbb) => {

    const targetComponent = availableComponents.find(c => c.abb === targetComponentAbb);
    setSelectedComponent(targetComponent);

    const { pcba: boards, abb, next_page_delay } = targetComponent;
    setTestingInputAbb(abb);
    setAvailableBoards(boards);
    if (setNextPageDelay != undefined) {
        setNextPageDelay(next_page_delay)
    }

    if (boards.length > 0) {
      const latestBoard = boards[0];
      setSelectedBoard(latestBoard);
      setSelectedBoardName(latestBoard.fqbn);

      const { version, package: packages, test_page_image_url: imageUrl } = latestBoard;

      setTestingInputVersion(version);
      setTestingInputImageUrl(imageUrl);
      setAvailablePackages(packages);

      if (packages.length > 0) {
        const latestPackage = packages[0];
        setSelectedPackage(latestPackage);

        const { id: packageId, test_timeout_sec: timeoutSeconds, firmware_set: firmwareSet } = latestPackage;
        setTestingInputPackageId(packageId);
        setTestingInputTimeoutSeconds(parseInt(timeoutSeconds));
        setSelectedFirmwareSet(firmwareSet);

        if (testingType === TESTING_TYPE.APP) {
          const { is_single_mcuid: isSingleMcuid } = latestPackage;
          setIsSingleMcuid(isSingleMcuid);
        }
      }
    }
  };

  const getTestingInfo = () => {
    setIsFetchingData(true);
    setIsLoadingFirmwareSelectUploadSection(true);
    apiRequest(apiPathForTestingInfo, "GET", null, "")
      .then((data) => {
        const { component: components, expiry: expiryTimestamp } = data;
        const cleanedUpComponents = cleanUpData(components);
        const currentTimestamp = Math.floor(new Date().getTime() / 1000);
        const cleanedUpExpiryTimestamp = Math.floor(expiryTimestamp);

        setAvailableComponents(cleanedUpComponents);
        setInfoRetrievedTimestamp(currentTimestamp);

        const infoJson = {
          components: cleanedUpComponents,
          retrievedTimestamp: currentTimestamp,
          expiryTimestamp: cleanedUpExpiryTimestamp
        };

        if (testingType === TESTING_TYPE.APP) {
          const { check_mcuid: checkMcuid } = data;
          const { firmware_set: firmwareSet, command, abb } = checkMcuid;
          infoJson.checkMcuid = checkMcuid;
          setCheckMcuidAbb(abb);
          setCheckMcuidCommand(command);
          setSelectedCheckMcuidFirmwareSet(firmwareSet);
        }

        localStorage.setItem(LS_KEY_TESTING_INFO[testingType], JSON.stringify(infoJson));

        setIsFetchingData(false);
        setIsLoadingFirmwareSelectUploadSection(false);
      })
      .catch((err) => {
        console.log(err);
        setIsFetchingData(false);
        setIsLoadingFirmwareSelectUploadSection(false);
      });
  };

  useEffect(() => {
    const persistedInfo = JSON.parse(localStorage.getItem(LS_KEY_TESTING_INFO[testingType]));

    if (jwtToken && !persistedInfo) {
      getTestingInfo();
      return;
    }

    if (persistedInfo) {
      const { expiryTimestamp } = persistedInfo;
      const currentTimestamp = Math.floor(new Date().getTime() / 1000);

      if (currentTimestamp > expiryTimestamp) {
        getTestingInfo();
        return;
      }

      const { components, retrievedTimestamp } = persistedInfo;
      setAvailableComponents(components);
      setInfoRetrievedTimestamp(retrievedTimestamp);

      if (testingType === TESTING_TYPE.APP) {
        const { checkMcuid } = persistedInfo;
        const { firmware_set: firmwareSet, abb, command } = checkMcuid;
        setCheckMcuidAbb(abb);
        setCheckMcuidCommand(command);
        setSelectedCheckMcuidFirmwareSet(firmwareSet);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jwtToken]);

  useEffect(() => {
    if (availableComponents && availableComponents.length > 0) {
      const firstComponent = availableComponents[0];
      setDefaultComponentValues(firstComponent.abb);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [availableComponents]);

  /* UI handlers */

  const handleSelectComponent = (e) => {
    resetFirmwareUploadSection();

    const targetComponentAbb = e.target.value;
    setDefaultComponentValues(targetComponentAbb);
  };

  const handleSelectBoard = (e) => {
    resetFirmwareUploadSection();

    const targetBoardVersion = e.target.value;
    const targetBoard = availableBoards.find(p => p.version === targetBoardVersion);
    setSelectedBoard(targetBoard);

    const { package: packages, version, fqbn, test_page_image_url: imageUrl } = targetBoard;

    setTestingInputVersion(version);
    setTestingInputImageUrl(imageUrl);
    setSelectedBoardName(fqbn);
    setAvailablePackages(packages);

    if (packages.length > 0) {
      const latestPackage = packages[0];
      setSelectedPackage(latestPackage);

      const { firmware_set: firmwareSet } = latestPackage;
      setSelectedFirmwareSet(firmwareSet);

      if (testingType === TESTING_TYPE.APP) {
        const { is_single_mcuid: isSingleMcuid } = latestPackage;
        setIsSingleMcuid(isSingleMcuid);
      }
    }
  };

  const handleSelectPackage = (e) => {
    resetFirmwareUploadSection();

    const targetPackgeId = parseInt(e.target.value);
    const targetPackage = availablePackages.find(p => p.id === targetPackgeId);
    setSelectedPackage(targetPackage);

    const { id: packageId, test_timeout_sec: timeoutSeconds, firmware_set: firmwareSet } = targetPackage;
    setTestingInputPackageId(packageId);
    setTestingInputTimeoutSeconds(parseInt(timeoutSeconds));
    setSelectedFirmwareSet(firmwareSet);

    if (testingType === TESTING_TYPE.APP) {
      const { is_single_mcuid: isSingleMcuid } = targetPackage;
      setIsSingleMcuid(isSingleMcuid);
    }
  };

  const getFirmwareSetDisplayValue = (firmwareSet) => {
    const firmwareNamesAndVersions = firmwareSet.map(f => `${f.name} v${f.version}`);
    return firmwareNamesAndVersions.join(" + ");
  };

  const displayDatetime = (timestamp) => {
    const date = new Date(parseInt(timestamp) * 1000);
    return date.toLocaleString();
  };

  const refreshTestingInfo = () => {
    resetFirmwareUploadSection();
    setSelectedBoardName(null);
    setSelectedFirmwareSet(null);
    getTestingInfo();
  };

  return (
    <section className={styles.mainSection}>
      <Paper className={classes.paper}>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <Grid container spacing={3}>
              {
                isFetchingData ?
                  <Grid item xs={12}>
                    <Skeleton variant="rect" width="100%" height={60} className={styles.skeleton} />
                    <Skeleton variant="rect" width="100%" height={60} className={styles.skeleton} />
                    <Skeleton variant="rect" width="100%" height={60} className={styles.skeleton} />
                  </Grid>
                  :
                  <>
                    {
                      (availableComponents && selectedComponent) &&
                      <Grid item xs={12}>
                        <TextField
                          id="outlined-select-currency"
                          select
                          label={testingType === TESTING_TYPE.SOM ? "Select SOM" : "Select Application"}
                          value={selectedComponent.abb}
                          onChange={handleSelectComponent}
                          variant="outlined"
                          fullWidth
                          className={styles.textField}
                          disabled={isUploadingFirmware}
                        >
                          {
                            availableComponents.map(c => (
                              <MenuItem key={c.abb} value={c.abb}>
                                {c.name}
                              </MenuItem>
                            ))
                          }
                        </TextField>
                      </Grid>
                    }
                    {
                      (availableBoards && selectedBoard) &&
                      <Grid item xs={12}>
                        <TextField
                          id="outlined-select-currency"
                          select
                          label="Select PCBA version"
                          value={selectedBoard.version}
                          onChange={handleSelectBoard}
                          variant="outlined"
                          fullWidth
                          className={styles.textField}
                          disabled={isUploadingFirmware}
                        >
                          {
                            availableBoards.map((p, index) => (
                              <MenuItem key={index} value={p.version}>
                                v{p.version}
                              </MenuItem>

                            ))
                          }
                        </TextField>
                      </Grid>
                    }
                    {
                      (availablePackages && selectedPackage) &&
                      <Grid item xs={12}>
                        <TextField
                          id="outlined-select-currency"
                          select
                          label="Select firmware package version"
                          value={selectedPackage.id}
                          onChange={handleSelectPackage}
                          variant="outlined"
                          fullWidth
                          className={styles.textField}
                          disabled={isUploadingFirmware}
                        >
                          {
                            availablePackages.map((p) => (
                              <MenuItem value={p.id} key={p.id}>
                                {getFirmwareSetDisplayValue(p.firmware_set)}
                              </MenuItem>
                            ))
                          }
                        </TextField>
                      </Grid>
                    }
                    {
                      infoRetrievedDatetime &&
                      <Grid item xs={12} className={styles.lastUpdatedGridItem}>
                        <Alert severity="info" className={styles.lastUpdatedAlert}>
                          Last updated: {displayDatetime(infoRetrievedDatetime)}
                        </Alert>
                        <Button
                          variant="contained"
                          color="default"
                          startIcon={<RefreshIcon />}
                          onClick={refreshTestingInfo}
                          disabled={isUploadingFirmware}
                        >
                          Refresh
                        </Button>
                      </Grid>
                    }
                  </>
              }
            </Grid>
          </Grid>
          <Grid item xs={6}>
            {
              isFetchingData ?
                <Grid item xs={12}>
                  <Skeleton variant="rect" width={400} height={400} />
                </Grid>
                :
                <>
                  {
                    (selectedBoard && selectedComponent) &&
                    <section className={styles.pcbaImageSection}>
                      <div className={styles.pcbaImageContainer}>
                        <img src={selectedBoard.upload_page_image_url ? selectedBoard.upload_page_image_url : `https://via.placeholder.com/400/cccccc/000000?text=${selectedComponent.name}`} alt="" />
                      </div>
                    </section>
                  }
                </>
            }
          </Grid>
        </Grid>
      </Paper>
    </section >
  );
}

FirmwareSelect.propTypes = {
  testingType: PropTypes.string.isRequired,
  apiPathForTestingInfo: PropTypes.string.isRequired,
  setTestingInputAbb: PropTypes.func.isRequired,
  setTestingInputVersion: PropTypes.func.isRequired,
  setTestingInputPackageId: PropTypes.func.isRequired,
  setTestingInputImageUrl: PropTypes.func.isRequired,
  setTestingInputTimeoutSeconds: PropTypes.func.isRequired,
  setIsLoadingFirmwareSelectUploadSection: PropTypes.func.isRequired,
  resetFirmwareUploadSection: PropTypes.func.isRequired,
  setSelectedBoardName: PropTypes.func.isRequired,
  setSelectedFirmwareSet: PropTypes.func.isRequired,
  isUploadingFirmware: PropTypes.bool.isRequired,
  setIsSingleMcuid: PropTypes.func,
  setSelectedCheckMcuidFirmwareSet: PropTypes.func,
  setCheckMcuidAbb: PropTypes.func,
  setCheckMcuidCommand: PropTypes.func
};