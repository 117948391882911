import React, { useEffect } from "react";
import Meta from "../components/Meta";
import DebugSection from "../components/DebugSection";
import { requireAuth } from "../util/auth";
import { LS_KEY_ROLE, ALLOWED_DEBUG_ROLES } from "../util/constants";

function DebugPage(props) {


  useEffect(() => {
    const role = localStorage.getItem(LS_KEY_ROLE);

    if (role && !ALLOWED_DEBUG_ROLES.includes(role)) {
      props.history.push("/dashboard");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Meta title="Debug" />
      <DebugSection />
    </>
  );
}

export default requireAuth(DebugPage);
