import React from 'react';
import styles from '../styles/som-testing-step-2.module.scss';
import {
  TESTING_TYPE
} from "../util/constants";
import FirmwareTest from './FirmwareTest';

export default function SOMTestingStep2({
  testingInputAbb,
  testingInputVersion,
  testingInputPackageId,
  testingInputImageUrl,
  testingInputTimeoutSeconds,
  testingInputCommand,
  isTestingFirmware,
  setIsTestingFirmware,
  isFirmwareTestingCompleted,
  setIsFirmwareTestingCompleted,
  setIsWebSerialPortOpen
}) {
  return (
    <section className={styles.mainSection}>
      <FirmwareTest
        testingType={TESTING_TYPE.SOM}
        testingInputPackageId={testingInputPackageId}
        testingInputAbb={testingInputAbb}
        testingInputCommand={testingInputCommand}
        testingInputVersion={testingInputVersion}
        testingInputImageUrl={testingInputImageUrl}
        testingInputTimeoutSeconds={testingInputTimeoutSeconds}
        isTestingFirmware={isTestingFirmware}
        setIsTestingFirmware={setIsTestingFirmware}
        isFirmwareTestingCompleted={isFirmwareTestingCompleted}
        setIsFirmwareTestingCompleted={setIsFirmwareTestingCompleted}
        setIsWebSerialPortOpen={setIsWebSerialPortOpen}
        testingInputMcuid={null}
        setTestingInputMcuid={null}
        testingInputSomIds={null}
        setTestingInputSomIds={null}
        isUploadingFirmware={null}
      />
    </section>
  );
}