import React from 'react';
import styles from '../styles/application-testing-step-2.module.scss';
import {
  IS_DEBUG_MODE,
  TESTING_TYPE
} from "../util/constants";
import FirmwareTest from './FirmwareTest';

export default function ApplicationTestingStep2({
  testingInputAbb,
  testingInputVersion,
  testingInputPackageId,
  testingInputImageUrl,
  testingInputTimeoutSeconds,
  testingInputCommand,
  isTestingFirmware,
  setIsTestingFirmware,
  isFirmwareTestingCompleted,
  setIsFirmwareTestingCompleted,
  setIsWebSerialPortOpen,
  testingType,
  setTestingInputMcuid,
  setTestingInputSomIds,
  testingInputMcuid,
  testingInputSomIds
}) {
  return (
    <section className={styles.mainSection}>
      {
        IS_DEBUG_MODE &&
        <section style={{border: "1px solid red", padding: 10}}>
          <strong>For development purpose only</strong>
          <section>
            <p>testingInputMcuid: {testingInputMcuid}</p>
            <input type="text" value={testingInputMcuid || ""} onChange={(e) => setTestingInputMcuid(e.target.value)} /> 
          </section>
          <hr />
          <section>
            <p>testingInputSomIds: {testingInputSomIds}</p>
            <p>separate with comma, e.g. 65,65</p>
            <input type="text" value={testingInputSomIds || ""} onChange={(e) => setTestingInputSomIds(e.target.value.split(",").map(Number))} /> 
          </section>
          <hr />
        </section>
      }
      <FirmwareTest
        testingInputAbb={testingInputAbb}
        testingInputVersion={testingInputVersion}
        testingInputPackageId={testingInputPackageId}
        testingInputImageUrl={testingInputImageUrl}
        testingInputTimeoutSeconds={3}
        testingInputCommand="test"
        testingInputMcuid={testingInputMcuid}
        testingInputSomIds={testingInputSomIds}
        isTestingFirmware={isTestingFirmware}
        setIsTestingFirmware={setIsTestingFirmware}
        isFirmwareTestingCompleted={isFirmwareTestingCompleted}
        setIsFirmwareTestingCompleted={setIsFirmwareTestingCompleted}
        setIsWebSerialPortOpen={setIsWebSerialPortOpen}
        testingType={TESTING_TYPE.APP}
        setTestingInputMcuid={setTestingInputMcuid}
        setTestingInputSomIds={setTestingInputSomIds}
      />
    </section>
  );
}