import {
  ALLOWED_DEBUG_ROLES,
  API_BASE_URL,
  LS_KEY_JWT_ACCESS_TOKEN,
  LS_KEY_ROLE
} from "./constants";

// Make an API request to any external URL
export function apiRequest(path, method = "GET", data, queryString = "") {

  const jwtToken = localStorage.getItem(LS_KEY_JWT_ACCESS_TOKEN);

  return fetch(`${API_BASE_URL}/${path}/${queryString}`, {
    method: method,
    headers: {
      accept: "application/json",
      "Content-Type": "application/json",
      ...(jwtToken && { "Authorization": `JWT ${jwtToken}` })
    },
    body: data ? JSON.stringify(data) : undefined,
  }).then((response) => {
    return response.json().catch(() => ({ status: response.status }));
  });
}

export function checkIsAdminOrEngineer() {
  const role = localStorage.getItem(LS_KEY_ROLE);
  return ALLOWED_DEBUG_ROLES.includes(role);
};

export function cleanUpData(components) {
  // Sort the objects in `firmware_set` by its `upload_order`
  components.forEach((component) => {
    component.pcba.forEach((pcba) => {
      pcba.package.forEach((pkg) => {
        pkg.firmware_set.sort((a, b) => a.upload_order - b.upload_order);
      });
    });
  });

  // Remove objects in `firmware_set` if its version is null
  components.forEach((component) => {
    component.pcba.forEach((pcba) => {
      pcba.package.forEach((pkg) => {
        pkg.firmware_set = pkg.firmware_set.filter((firmware) => firmware.version !== null);
      });
    });
  });

  // Add is_optional property to objects in `firmware_set` array if it doesn't exist
  components.forEach((component) => {
    component.pcba.forEach((pcba) => {
      pcba.package.forEach((pkg) => {
        pkg.firmware_set.forEach((firmware) => {
          if (!firmware.hasOwnProperty('is_optional')) {
            firmware.is_optional = false;
          }
        });
      });
    });
  });

  // Sort the array of `pcba` objects by its version
  components.forEach(component => {
    component.pcba.sort((a, b) => {
      const aVersion = parseFloat(a.version);
      const bVersion = parseFloat(b.version);
      if (aVersion > bVersion) {
        return -1;
      }
      if (aVersion < bVersion) {
        return 1;
      }
      return 0;
    });
  });

  // Sort the array of `package` objects by the `version` of the first object in the `firmware_set` array
  components.forEach((component) => {
    component.pcba.forEach((pcba) => {
      pcba.package.sort((a, b) => {
        const versionA = a.firmware_set[0].version;
        const versionB = b.firmware_set[0].version;

        if (versionA < versionB) {
          return 1;
        } else if (versionA > versionB) {
          return -1;
        }
        return 0;
      });
    });
  });
  return components;
}