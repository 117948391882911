import React from "react";
import Footer from "./../components/Footer";
import Navbar from "./../components/Navbar";
import { AuthProvider } from "./../util/auth";
import { Route, Router, Switch } from "./../util/router";
import { ThemeProvider } from "./../util/theme";
import NotFoundPage from "./404";
import AuthPage from "./auth";
import DashboardPage from "./dashboard";
import DebugPage from "./debug";
import IndexPage from "./index";
import ApplicationTestingPage from "./application-testing";
import ProductionFirmwareUploadPage from "./production-firmware-upload";
import AllFirmwareUploadPage from "./all-firmware-upload";
import SOMTestingPage from "./som-testing";

function App(props) {
  return (
    <ThemeProvider>
      <AuthProvider>
        <Router>
          <>
            <Navbar
              color="default"
              logo="/logo.svg"
              logoInverted="/logo-white.svg"
            />

            <Switch>
              <Route exact path="/" component={IndexPage} />

              <Route exact path="/dashboard" component={DashboardPage} />

              <Route exact path="/som-testing" component={SOMTestingPage} />

              <Route exact path="/application-testing" component={ApplicationTestingPage} />

              <Route exact path="/production-firmware-upload" component={ProductionFirmwareUploadPage} />

              <Route exact path="/all-firmware-upload" component={AllFirmwareUploadPage} />

              <Route exact path="/debug" component={DebugPage} />

              <Route exact path="/auth/:type" component={AuthPage} />

              <Route component={NotFoundPage} />
            </Switch>

            <Footer
              bgColor="light"
              size="normal"
              bgImage=""
              bgImageOpacity={1}
              description="A short description of what you do here"
              copyright={`© ${new Date().getFullYear()}`}
              logo="/logo.svg"
              logoInverted="/logo-white.svg"
              sticky={true}
            />
          </>
        </Router>
      </AuthProvider>
    </ThemeProvider>
  );
}

export default App;
