import React from "react";
import Meta from "./../components/Meta";
import HeroSection from "./../components/HeroSection";

function IndexPage(props) {
  return (
    <>
      <Meta />
      <HeroSection
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Cloudatik PCBA Testing"
        subtitle=""
        image="/hero-section.svg"
        buttonText="Sign In"
        buttonColor="primary"
        buttonPath="/auth/signin"
      />
    </>
  );
}

export default IndexPage;
