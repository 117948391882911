import Button from '@material-ui/core/Button';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';
import Typography from '@material-ui/core/Typography';
import WbIncandescentIcon from '@material-ui/icons/WbIncandescent';
import MuiAlert from '@material-ui/lab/Alert';
import { useState } from 'react';
import styles from '../styles/application-testing-section.module.scss';
import {
  checkIsAdminOrEngineer
} from "../util/util";
import ApplicationTestingStep1 from './ApplicationTestingStep1';
import ApplicationTestingStep2 from './ApplicationTestingStep2';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function ApplicationTestingSection(props) {
  const isAdminOrEngineer = checkIsAdminOrEngineer();

  // Parameters are needed by `SOMFirmwareTestingSection` but set by `SOMFirmwareSelectUploadSection`
  const [testingInputAbb, setTestingInputAbb] = useState(null);
  const [testingInputVersion, setTestingInputVersion] = useState(null);
  const [testingInputPackageId, setTestingInputPackageId] = useState(null);
  const [testingInputImageUrl, setTestingInputImageUrl] = useState(null);
  const [testingInputTimeoutSeconds, setTestingInputTimeoutSeconds] = useState(null);
  const [testingInputMcuid, setTestingInputMcuid] = useState(null);
  const [testingInputSomIds, setTestingInputSomIds] = useState([]);

  // To control navigation buttons for the stepper
  const [isLoadingFirmwareSelectUploadSection, setIsLoadingFirmwareSelectUploadSection] = useState(false);
  const [isUploadingFirmware, setIsUploadingFirmware] = useState(false);
  const [isFirmwareUploadCompleted, setIsFirmwareUploadCompleted] = useState(false);
  const [isTestingFirmware, setIsTestingFirmware] = useState(false);
  const [isFirmwareTestingCompleted, setIsFirmwareTestingCompleted] = useState(false);
  const [isWebSerialPortOpen, setIsWebSerialPortOpen] = useState(false);

  // Stepper UI
  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return <ApplicationTestingStep1
          setTestingInputAbb={setTestingInputAbb}
          setTestingInputVersion={setTestingInputVersion}
          setTestingInputPackageId={setTestingInputPackageId}
          setTestingInputImageUrl={setTestingInputImageUrl}
          setTestingInputTimeoutSeconds={setTestingInputTimeoutSeconds}
          setTestingInputMcuid={setTestingInputMcuid}
          setTestingInputSomIds={setTestingInputSomIds}        

          setIsLoadingFirmwareSelectUploadSection={setIsLoadingFirmwareSelectUploadSection}
          setIsUploadingFirmware={setIsUploadingFirmware}
          setIsFirmwareUploadCompleted={setIsFirmwareUploadCompleted}

          isFirmwareUploadCompleted={isFirmwareUploadCompleted}
          isUploadingFirmware={isUploadingFirmware}

          testingInputMcuid={testingInputMcuid}

          isWebSerialPortOpen={isWebSerialPortOpen}
          setIsWebSerialPortOpen={setIsWebSerialPortOpen}
        />;
      case 1:
        return <ApplicationTestingStep2
          testingInputAbb={testingInputAbb}
          testingInputVersion={testingInputVersion}
          testingInputPackageId={testingInputPackageId}
          testingInputImageUrl={testingInputImageUrl}
          testingInputTimeoutSeconds={testingInputTimeoutSeconds}
          isTestingFirmware={isTestingFirmware}
          setIsTestingFirmware={setIsTestingFirmware}
          isFirmwareTestingCompleted={isFirmwareTestingCompleted}
          setIsFirmwareTestingCompleted={setIsFirmwareTestingCompleted}
          setIsWebSerialPortOpen={setIsWebSerialPortOpen}
          testingInputMcuid={testingInputMcuid}
          testingInputSomIds={testingInputSomIds}
          setTestingInputMcuid={setTestingInputMcuid}
          setTestingInputSomIds={setTestingInputSomIds}
        />;
      default:
        console.log('[ERROR] Unknown step');
        return null;
    }
  };

  const [activeStep, setActiveStep] = useState(0);
  const steps = ['Upload', 'Test'];

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    setIsFirmwareUploadCompleted(false);
    setIsFirmwareTestingCompleted(false);
  };

  const handleReset = () => {
    setActiveStep(0);
    setIsFirmwareUploadCompleted(false);
    setIsFirmwareTestingCompleted(false);

    setTestingInputAbb(null);
    setTestingInputVersion(null);
    setTestingInputPackageId(null);
    setTestingInputImageUrl(null);
    setTestingInputTimeoutSeconds(null);
    setTestingInputMcuid(null);
    setTestingInputSomIds([]);
  };

  const isBackBtnDisabled = () => {
    return !isAdminOrEngineer || activeStep === 0 || isWebSerialPortOpen ? true : false;
  };

  const isFinishNextBtnDisabled = () => {
    return (isAdminOrEngineer && !isWebSerialPortOpen && !isLoadingFirmwareSelectUploadSection && !isUploadingFirmware && !isTestingFirmware) ||
     (activeStep === 0 && isFirmwareUploadCompleted) || 
     (activeStep === 1 && isFirmwareTestingCompleted && !isWebSerialPortOpen) ? false : true;
  };

  return (
    <section className={styles.mainSection}>
      <Typography variant="h4" component="h1" className={styles.heading}>
        <WbIncandescentIcon fontSize="large" />
        Application Testing
      </Typography>
      <section>
        <Stepper activeStep={activeStep}>
          {steps.map((label, index) => {
            const stepProps = {};
            const labelProps = {};
            return (
              <Step key={label} {...stepProps}>
                <StepLabel {...labelProps}>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
        <section>
          {activeStep === steps.length ? (
            <section className={styles.allStepsCompleted}>
              <h2>
                All steps completed!
              </h2>
              <Button
                variant="contained"
                color="primary"
                onClick={handleReset}
              >
                Reset
              </Button>
            </section>
          ) : (
            <section>
              <section>
                {getStepContent(activeStep)}
              </section>
              <section className={styles.navBtns}>
                {isWebSerialPortOpen &&
                  <Alert severity="warning" className={styles.alert}>
                    Disconnect from Serial Port first before navigating.
                  </Alert>
                }
                <div>
                  <Button
                    disabled={isBackBtnDisabled()}
                    onClick={handleBack}
                  >
                    Back
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleNext}
                    disabled={isFinishNextBtnDisabled()}
                  >
                    {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                  </Button>
                </div>
              </section>
            </section>
          )}
        </section>
      </section>
    </section >
  );
};