import React from "react";
import Meta from "../components/Meta";
import AllFirmwareUploadSection from "../components/AllFirmwareUploadSection";
import { requireAuth } from "../util/auth";

function AllFirmwareUploadPage(props) {
  return (
    <>
      <Meta title="All Firmware Upload" />
      <AllFirmwareUploadSection />
    </>
  );
}

export default requireAuth(AllFirmwareUploadPage);
