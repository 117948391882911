import React from 'react';
import styles from '../styles/dashboard-section.module.scss';
import { useAuth } from "../util/auth";
import {
  LS_KEY_ROLE
} from "../util/constants";
import {
  checkIsAdminOrEngineer
} from "../util/util";

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core/styles';
import BugReportIcon from '@material-ui/icons/BugReport';
import PublishIcon from '@material-ui/icons/Publish';
import WbIncandescentIcon from '@material-ui/icons/WbIncandescent';
import SettingsIcon from '@material-ui/icons/Settings';
import BuildIcon from '@material-ui/icons/Build';
import Chip from '@material-ui/core/Chip';

import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  paper: {
    width: '100%',
    height: 'auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(1),
      padding: theme.spacing(1),
    },
  }
}));

function DashboardSection(props) {
  const auth = useAuth();

  const classes = useStyles();

  const role = localStorage.getItem(LS_KEY_ROLE);

  const isAdminOrEngineer = checkIsAdminOrEngineer();

  return (
    <div className={classes.paper}>
      <Paper elevation={3} className={styles.paper}>
        <section className={styles.mainSection}>
          <h1>Welcome {auth.user.username}!
          </h1>
          <div className={styles.chip}>
            <Chip label={role} />
          </div>
          <section className={styles.list}>
            <div className={classes.root}>
              <List component="nav" aria-label="main mailbox folders">
                <ListItem button component="a" href="/som-testing">
                  <ListItemIcon>
                    <BuildIcon />
                  </ListItemIcon>
                  <ListItemText primary="SOM Testing" />
                </ListItem>
                <ListItem button component="a" href="/application-testing">
                  <ListItemIcon>
                    <WbIncandescentIcon />
                  </ListItemIcon>
                  <ListItemText primary="Application Testing" />
                </ListItem>
                <ListItem button component="a" href="/production-firmware-upload">
                  <ListItemIcon>
                    <PublishIcon />
                  </ListItemIcon>
                  <ListItemText primary="Production Firmware Upload" />
                </ListItem>
                {
                  isAdminOrEngineer &&
                  <>
                    <ListItem button component="a" href="/all-firmware-upload">
                      <ListItemIcon>
                        <SettingsIcon />
                      </ListItemIcon>
                      <ListItemText primary="All Firmware Upload" />
                    </ListItem>
                    <ListItem button component="a" href="/debug">
                      <ListItemIcon>
                        <BugReportIcon />
                      </ListItemIcon>
                      <ListItemText primary="Debug" />
                    </ListItem>
                  </>
                }
              </List>
            </div>
          </section>
        </section>
      </Paper>
    </div>
  );
}

export default DashboardSection;
