import React from "react";
import Meta from "../components/Meta";
import SOMTestingSection from "../components/SOMTestingSection";
import { requireAuth } from "../util/auth";

function SOMTestingPage(props) {
  return (
    <>
      <Meta title="SOM Testing" />
      <SOMTestingSection/>
    </>
  );
}

export default requireAuth(SOMTestingPage);
