import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SearchIcon from '@material-ui/icons/Search';
import MuiAlert from '@material-ui/lab/Alert';
import { useRequest } from "ahooks";
import PropTypes from "prop-types";
import React, { useEffect, useState } from 'react';
import BarLoader from "react-spinners/BarLoader";
import styles from '../styles/firmware-upload.module.scss';
import {
  AGENT_BASE_URL,
  AGENT_PATH_AGENT_STATUS,
  AGENT_PATH_AGENT_VERSION,
  AGENT_PATH_ARDUINO_CLI_STATUS,
  AGENT_PATH_SERIAL_PORTS,
  AGENT_PATH_UPLOAD_FIRMWARE,
  AGENT_REQUIRED_VERSION,
  DOWNLOAD_URL_ARDUINO_CLI,
  DOWNLOAD_URL_CLOUDATIK_AGENT,
  UPLOAD_TYPE
} from "../util/constants";
import {
  checkIsAdminOrEngineer
} from "../util/util";

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    padding: theme.spacing(2),
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },

}));

function Alert(props) {
  return <MuiAlert {...props} />;
}

export default function FirmwareUpload({
  uploadType,
  selectedBoardName,
  selectedFirmwareSet,
  isUploadingFirmware,
  setIsUploadingFirmware,
  isFirmwareUploadCompleted,
  setIsFirmwareUploadCompleted,
  firmwareUploadMessage,
  setFirmwareUploadMessage,
  firmwareFileNamesMessage,
  setFirmwareFileNamesMessage,
  firmwareUploadStdout,
  setFirmwareUploadStdout,
  currentUploadOrder,
  setCurrentUploadOrder,
  isFirmwareUploadSuccessful,
  setIsFirmwareUploadSuccessful,
  firmwareUploadSuccessMessage,
  setFirmwareUploadSuccessMessage,
  isFirmwareUploadFailed,
  setIsFirmwareUploadFailed,
  firmwareUploadFailedMessage,
  setFirmwareUploadFailedMessage,
  isWebSerialPortOpen,
  selectedCheckMcuidFirmwareSet,
  targetUploadOrder,
  setTargetUploadOrder,
  isSingleMcuid,
  isMcuidChecked,
  setIsMcuidChecked,
  setIsMcuidFirmwareUploaded,
  setIsMcuidTestingDisabled
}) {
  const classes = useStyles();

  const [isAgentRunning, setIsAgentRunning] = useState(false);
  const [agentVersion, setAgentVersion] = useState(null);
  const [isAgentVersionOutdated, setIsAgentVersionOutdated] = useState(false);
  const [isArduinoCliInstalled, setIsArduinoCliInstalled] = useState(false);
  const [arduinoCliVersion, setArduinoCliVersion] = useState(null);
  const [portAddresses, setPortAddresses] = useState(null);
  const [selectedPortAddress, setSelectedPortAddress] = useState("");
  const [isSearchingPorts, setIsSearchingPorts] = useState(false);

  const [isPortConfirmed, setIsPortConfirmed] = useState(false);

  const answer = {
    pending: 0,
    yes: 1,
    no: 2
  };

  const [promptAnswer, setPromptAnswer] = useState(answer.pending);

  const isAdminOrEngineer = checkIsAdminOrEngineer();

  /* Check that Cloudatik Agent and Arduino CLI are installed.  */

  const checkIfAgentIsRunning = () => {
    return new Promise((resolve) => {
      fetch(`${AGENT_BASE_URL}/${AGENT_PATH_AGENT_STATUS}`)
        .then((res) => res.json())
        .then((data) => {
          const { isRunning } = data;
          if (isRunning) {
            setIsAgentRunning(true);
            resolve(true);
          }
        })
        .catch((err) => {
          setIsAgentRunning(false);
          resolve(false);
        });
    });
  };

  const checkAgentVersion = () => {
    fetch(`${AGENT_BASE_URL}/${AGENT_PATH_AGENT_VERSION}`)
      .then((res) => res.json())
      .then((data) => {
        const { version } = data;
        if (version !== AGENT_REQUIRED_VERSION) {
          setIsAgentVersionOutdated(true);
        }
        setAgentVersion(version);
      })
      .catch((err) => {
        setIsAgentVersionOutdated(true);
      });
  };

  useRequest(checkIfAgentIsRunning, {
    pollingInterval: 3000,
    pollingWhenHidden: false,
  });

  useEffect(() => {
    if (isAgentRunning) {
      fetch(`${AGENT_BASE_URL}/${AGENT_PATH_ARDUINO_CLI_STATUS}`)
        .then((res) => res.json())
        .then((data) => {
          const { isInstalled, version } = data;
          if (isInstalled) {
            setIsArduinoCliInstalled(true);
            setArduinoCliVersion(version);
          }
        })
        .catch((err) => {
          console.log(err);
          setIsArduinoCliInstalled(false);
        });
    }
  }, [isAgentRunning]);

  useEffect(() => {
    if (isAgentRunning) {
      checkAgentVersion();
    }
  }, [isAgentRunning]);

  const getPorts = () => {
    if (isArduinoCliInstalled) {
      setIsSearchingPorts(true);
      fetch(`${AGENT_BASE_URL}/${AGENT_PATH_SERIAL_PORTS}`)
        .then((res) => res.json())
        .then((data) => {
          const { ports } = data;
          const addresses = ports.map((p) => p.port.address);
          setPortAddresses(addresses);
          setIsSearchingPorts(false);
        })
        .catch((err) => {
          console.log(err);
          setIsSearchingPorts(false);
        });
    }
  };

  useEffect(() => {
    if (isAgentRunning && isArduinoCliInstalled) {
      getPorts();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAgentRunning, isArduinoCliInstalled]);

  const uploadFirmware = () => {

    setIsPortConfirmed(false);

    setFirmwareUploadMessage("");
    setIsFirmwareUploadSuccessful(false);
    setIsFirmwareUploadFailed(false);

    let fileUrls;

    switch (uploadType) {
      case UPLOAD_TYPE.CHECK_MCUID:
        fileUrls = selectedCheckMcuidFirmwareSet.url;
        break;
      case UPLOAD_TYPE.SOM:
        fileUrls = selectedFirmwareSet[currentUploadOrder - 1].url;
        break;
      case UPLOAD_TYPE.APP:
        if (isSingleMcuid) {
            fileUrls = selectedFirmwareSet[currentUploadOrder - 1].url;
        } else {
          fileUrls = selectedFirmwareSet[targetUploadOrder - 1].url;
        }
        break;
      default:
        console.log("[ERROR] Invalid upload type");
        break;
    }

    const displayFileNames = fileUrls.map((url) =>
      url.split("/").pop().split("?").shift()
    ).join(" \n");

    setFirmwareFileNamesMessage(displayFileNames);
    setFirmwareUploadStdout("");

    const formData = new FormData();
    formData.append("portAddress", selectedPortAddress);
    formData.append("boardName", selectedBoardName);

    console.log("[INFO] Uploading firmware...");
    setIsUploadingFirmware(true);
    // Fetch all files and append them to the formData object
    Promise.all(
      fileUrls.map((url) =>
        fetch(url)
          .then((response) => response.blob())
          .then((blob) => {
            // extract the filename from the url
            const filename = url.split("/").pop().split("?").shift();
            console.log("[INFO] File:", filename);

            formData.append("firmware", blob, filename);
          })
          .catch((error) => console.error(error))
      )
    )
      .then(() => {
        // Send a POST request to the server with the FormData object
        fetch(`${AGENT_BASE_URL}/${AGENT_PATH_UPLOAD_FIRMWARE}`, {
          method: "POST",
          body: formData,
        })
          .then((response) => response.json())
          .then((data) => {

            if (data.status === "success") {
              console.log("[SUCCESS] Firmware uploaded.");
              setIsFirmwareUploadSuccessful(true);
              setIsFirmwareUploadFailed(false);

              if (uploadType === UPLOAD_TYPE.APP && isSingleMcuid) { 
                setIsMcuidTestingDisabled(true)
              }

              if (uploadType === UPLOAD_TYPE.SOM || (uploadType === UPLOAD_TYPE.APP && isSingleMcuid)) {
                if (currentUploadOrder < selectedFirmwareSet.length) {
                  setFirmwareUploadSuccessMessage(`Firmware ${currentUploadOrder}/${selectedFirmwareSet.length} uploaded.`);
                  setCurrentUploadOrder(prevUploadOrder => prevUploadOrder + 1);
                } else {
                  setFirmwareUploadSuccessMessage(`Firmware ${currentUploadOrder}/${selectedFirmwareSet.length} uploaded.`);
                  setIsFirmwareUploadCompleted(true);
                  setIsMcuidChecked(false);
                }  
              }

              if (uploadType === UPLOAD_TYPE.APP && !isSingleMcuid) {
                if (targetUploadOrder < selectedFirmwareSet.length) {
                  setTargetUploadOrder(prevUploadOrder => prevUploadOrder + 1);
                  setIsMcuidChecked(false);
                  setIsMcuidFirmwareUploaded(false);
                } else {
                  setIsFirmwareUploadCompleted(true);
                  setIsMcuidChecked(true);
                  setIsMcuidFirmwareUploaded(true);
                }  
                setFirmwareUploadSuccessMessage(`Firmware #${targetUploadOrder} uploaded for ${selectedFirmwareSet[targetUploadOrder - 1].name}.`);
              }
              
              if (uploadType === UPLOAD_TYPE.CHECK_MCUID) {
                setIsMcuidFirmwareUploaded(true);
                setFirmwareUploadSuccessMessage(`Firmware for checking MCUID uploaded.`);
              }
            } else {
              console.log("[ERROR] Firmware upload failed.");
              setIsFirmwareUploadSuccessful(false);
              setIsFirmwareUploadFailed(true);
              if (uploadType === UPLOAD_TYPE.SOM) {
                setFirmwareUploadFailedMessage(`Firmware ${currentUploadOrder}/${selectedFirmwareSet.length} upload failed.`);
              }
              
              if (uploadType === UPLOAD_TYPE.CHECK_MCUID) {
                setFirmwareUploadSuccessMessage(`Firmware for checking MCUID upload failed.`);
              }
              setFirmwareUploadMessage(data.message);
            }

            setFirmwareUploadStdout(data.stdout);
            setIsUploadingFirmware(false);
          })
          .catch((error) => {
            setIsUploadingFirmware(false);
          });
      })
      .catch((error) => { console.error(error); });
  };

  const skipFirmwareUpload = () => {
    setIsFirmwareUploadCompleted(true);
  };


  useEffect(() => {
    setIsFirmwareUploadCompleted(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  let chipLabel;
  switch (uploadType) {
    case UPLOAD_TYPE.SOM:
      chipLabel = "Upload SOM test firmware"
      break;
    case UPLOAD_TYPE.APP:
      chipLabel = "Upload production firmware"
      break;
    case UPLOAD_TYPE.CHECK_MCUID:
      chipLabel = "Upload test firmware for checking MCUID"
      break;
    default:
      console.log("[ERROR] Invalid upload type");
      break;
  }

  return (
    <section className={styles.mainSection}
      style={((uploadType === UPLOAD_TYPE.APP && !isMcuidChecked) ||
              (uploadType === UPLOAD_TYPE.CHECK_MCUID && isMcuidChecked)) ? 
      { opacity: 0.5, pointerEvents: "none"} : {} }>
      <Paper className={classes.paper}>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <section>
              <Chip 
                label={chipLabel}
                variant="outlined"  
                color="primary"
              />
              <h4>Cloudatik Agent: {
                isAgentRunning ? (
                  isAgentVersionOutdated ?
                    `⚠️ ${agentVersion ? agentVersion : "unknown version"}` :
                    `✅ ${agentVersion}`
                ) :
                  "🚫 not running"}</h4>
              {
                !isAgentRunning &&
                <>
                  <Alert severity="warning" className={styles.prompt}>
                    Have you installed Cloudatik Agent?
                    <p>

                      <Button
                        variant="contained"
                        color="primary"
                        className={styles.yesNoButton}
                        onClick={() => { setPromptAnswer(answer.yes); }}
                      >
                        Yes
                      </Button>
                      <Button
                        variant="contained"
                        color="secondary"
                        className={styles.yesNoButton}
                        onClick={() => { setPromptAnswer(answer.no); }}
                      >
                        No
                      </Button>
                    </p>
                  </Alert>
                  {
                    promptAnswer === answer.yes &&
                    <Alert severity="info">
                      Please open the Cloudatik Agent.
                    </Alert>
                  }
                  {
                    promptAnswer === answer.no &&
                    <Alert severity="info">
                      <a href={DOWNLOAD_URL_CLOUDATIK_AGENT}>Please download and install Cloudatik Agent {AGENT_REQUIRED_VERSION} for Windows.</a>
                    </Alert>
                  }
                </>
              }
              {
                isAgentRunning && isAgentVersionOutdated &&
                <>
                  <p>⚠️ Installed agent is outdated, please use version {AGENT_REQUIRED_VERSION}.</p>
                  <a href={DOWNLOAD_URL_CLOUDATIK_AGENT}>⬇️ Download and install Cloudatik Agent {AGENT_REQUIRED_VERSION} for Windows</a>
                </>
              }
              {isAgentRunning && (
                <>
                  <h4>
                    Arduino CLI:{" "}
                    {isArduinoCliInstalled ? `✅ ${arduinoCliVersion}` : "🚫 not installed"}
                  </h4>
                  {
                    !isArduinoCliInstalled && <a href={DOWNLOAD_URL_ARDUINO_CLI}>⬇️ Download Arduino CLI for Windows</a>
                  }
                </>
              )}
              {isAgentRunning && isArduinoCliInstalled && (
                <>
                  {portAddresses && (
                    <section className={styles.portSelection}>
                      <h4>
                        Selected port:{" "}
                        {selectedPortAddress ? `✅ ${selectedPortAddress}` : "🚫"}
                      </h4>
                      <TextField
                        id="outlined-select-currency"
                        select
                        label="Select port"
                        value={selectedPortAddress}
                        onChange={(e) => setSelectedPortAddress(e.target.value)}
                        variant="outlined"
                        fullWidth
                        className={styles.selectDropdown}
                        disabled={isSearchingPorts || isUploadingFirmware}
                      >
                        {
                          portAddresses.map(p => (
                            <MenuItem key={p} value={p}>
                              {p}
                            </MenuItem>
                          ))
                        }
                      </TextField>
                      <Button
                        variant="contained"
                        startIcon={<SearchIcon />}
                        className={styles.searchPortsButton}
                        color="default"
                        onClick={getPorts}
                        disabled={isSearchingPorts || isUploadingFirmware}
                      >
                        Search ports
                      </Button>
                      {
                        isSearchingPorts && (
                          <BarLoader
                            color="#36d7b7"
                            width="100%"
                          />
                        )
                      }
                    </section>
                  )}
                  {selectedPortAddress && selectedBoardName && 
                  (uploadType === UPLOAD_TYPE.CHECK_MCUID ? selectedCheckMcuidFirmwareSet : selectedFirmwareSet) && (
                    <>
                      <section className={styles.uploadFirmwareButton}>
                        {
                          isWebSerialPortOpen &&
                          <Alert severity="warning" className={styles.alert}>
                            Disconnect from web serial port first to enable firmware upload using agent.
                          </Alert>
                        }
                        {
                          (uploadType === UPLOAD_TYPE.APP && !isSingleMcuid) &&
                          <section className={styles.confirmPort}>
                            <Alert severity="warning" className={styles.alert}>
                              This DUT has multiple MCUID. <br />
                              {`Connect to the port for `}<strong>{`${selectedFirmwareSet[targetUploadOrder - 1].name}.`}</strong> <br />
                              {`Confirm that the MCUID for `}<strong>{`${selectedFirmwareSet[targetUploadOrder - 1].name}`}</strong> is checked.
                            </Alert>
                            <Button
                              variant="contained"
                              color="secondary"
                              onClick={() => { setIsPortConfirmed(true); }}
                            >
                              Confirm port is correct and MCUID is checked.
                            </Button>
                          </section>
                        }
                        {
                          (uploadType === UPLOAD_TYPE.CHECK_MCUID && !isSingleMcuid) &&
                          <section className={styles.confirmPort}>
                            <Alert severity="warning" className={styles.alert}>
                              This DUT has multiple MCUID. <br />
                              Confirm that the port is correct.
                            </Alert>
                            <Button
                              variant="contained"
                              color="secondary"
                              onClick={() => { setIsPortConfirmed(true); }}
                            >
                              Confirm port is correct.
                            </Button>
                          </section>
                        }
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={uploadFirmware}
                          disabled={
                            isUploadingFirmware || 
                            isSearchingPorts || 
                            isFirmwareUploadCompleted || 
                            isWebSerialPortOpen ||
                            (uploadType === UPLOAD_TYPE.APP && !isSingleMcuid && !isPortConfirmed) ||
                            (uploadType === UPLOAD_TYPE.CHECK_MCUID && !isSingleMcuid && !isPortConfirmed) 
                        }
                        >
                          {
                            uploadType === UPLOAD_TYPE.CHECK_MCUID &&
                              `Upload test firmware to check MCUID`
                          }
                          {
                            uploadType === UPLOAD_TYPE.SOM &&
                              `Upload SOM test firmware ${currentUploadOrder}/${selectedFirmwareSet.length}`
                          }
                          {
                            (uploadType === UPLOAD_TYPE.APP && isSingleMcuid) &&
                              `Upload production firmware ${currentUploadOrder}/${selectedFirmwareSet.length}`
                          }
                          {
                            (uploadType === UPLOAD_TYPE.APP && !isSingleMcuid) &&
                              `Upload production firmware #${targetUploadOrder} for ${selectedFirmwareSet[targetUploadOrder - 1].name}`
                          }
                        </Button>
                      </section>
                      {
                        selectedFirmwareSet[currentUploadOrder - 1].is_optional && (
                          <section className={styles.skipFirmwareButton}>
                            <Button
                              variant="contained"
                              color="secondary"
                              onClick={skipFirmwareUpload}
                              disabled={isUploadingFirmware || isFirmwareUploadCompleted || isSearchingPorts}
                              className={styles.skipFirmwareButton}
                            >
                              Skip firmware {currentUploadOrder}/{selectedFirmwareSet.length}
                            </Button>
                            <Alert severity="warning" className={styles.alert}>
                              Firmware {currentUploadOrder}/{selectedFirmwareSet.length} is optional.
                            </Alert>
                          </section>
                        )
                      }
                      {
                        selectedFirmwareSet && uploadType === UPLOAD_TYPE.SOM &&
                        <section className={styles.firmwareInfo}>
                          <Alert severity="info" className={styles.alert}>
                            <div>
                              Firmware {currentUploadOrder}/{selectedFirmwareSet.length}:
                            </div>
                            <div>
                              {selectedFirmwareSet[currentUploadOrder - 1].name} v{selectedFirmwareSet[currentUploadOrder - 1].version}
                            </div>
                            <div>
                              {selectedFirmwareSet[currentUploadOrder - 1].remark && `Remarks: ${selectedFirmwareSet[currentUploadOrder - 1].remark}`}
                            </div>
                          </Alert>
                        </section>
                      }
                      {
                        isUploadingFirmware && (
                          <>
                            <BarLoader
                              color="#36d7b7"
                              width="100%"
                            />
                            <p>
                              {
                                uploadType === UPLOAD_TYPE.SOM && `Uploading firmware ${currentUploadOrder}/${selectedFirmwareSet.length}...`
                              }
                              {
                                uploadType === UPLOAD_TYPE.CHECK_MCUID && `Uploading firmware for checking MCUID...`
                              }
                            </p>
                          </>
                        )
                      }
                      {
                        isFirmwareUploadSuccessful && firmwareUploadSuccessMessage &&
                        <Alert
                          severity="success"
                          className={styles.firmwareUploadSuccessFailedAlert}
                        >
                          {firmwareUploadSuccessMessage}
                        </Alert>
                      }
                      {
                        isFirmwareUploadFailed && firmwareUploadFailedMessage &&
                        <Alert severity="error" className={styles.firmwareUploadSuccessFailedAlert}>
                          {firmwareUploadFailedMessage}
                        </Alert>
                      }
                      {
                        firmwareUploadMessage &&
                        <section className={styles.firmwareUploadMessage}>
                          <pre>
                            {firmwareUploadMessage}
                          </pre>
                        </section>
                      }
                      {
                        (firmwareFileNamesMessage && isAdminOrEngineer) && (
                          <section className={styles.firmwareFileNamesMessage}>
                            <h4>🔒 Firmware files</h4>
                            <ol>
                              {
                                firmwareFileNamesMessage.split("\n").map((line, index) => (
                                  <li key={index} className={styles.firmwareUploadMessage}>
                                    {line}
                                  </li>))
                              }
                            </ol>
                          </section>
                        )
                      }
                    </>
                  )}
                </>
              )}
            </section>
          </Grid>
          <Grid item xs={12}>
            {(firmwareUploadStdout && isAdminOrEngineer) &&
              <section className={styles.firmwareUploadStdout}>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                  >
                    <Typography className={classes.heading}>🔒 Logs</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <pre>{firmwareUploadStdout}</pre>
                  </AccordionDetails>
                </Accordion>
              </section>
            }
          </Grid>
        </Grid>
      </Paper>
    </section >
  );
}

FirmwareUpload.propTypes = {
  uploadType: PropTypes.string.isRequired,
  selectedBoardName: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.oneOf([null])]),
  selectedFirmwareSet: PropTypes.oneOfType([PropTypes.array.isRequired, PropTypes.oneOf([null])]),
  isUploadingFirmware: PropTypes.bool.isRequired,
  setIsUploadingFirmware: PropTypes.func.isRequired,
  isFirmwareUploadCompleted: PropTypes.bool.isRequired,
  setIsFirmwareUploadCompleted: PropTypes.func.isRequired,
  firmwareUploadMessage: PropTypes.string.isRequired,
  setFirmwareUploadMessage: PropTypes.func.isRequired,
  firmwareFileNamesMessage: PropTypes.string.isRequired,
  setFirmwareFileNamesMessage: PropTypes.func.isRequired,
  firmwareUploadStdout: PropTypes.string.isRequired,
  setFirmwareUploadStdout: PropTypes.func.isRequired,
  currentUploadOrder: PropTypes.number.isRequired,
  setCurrentUploadOrder: PropTypes.func.isRequired,
  isFirmwareUploadSuccessful: PropTypes.bool.isRequired,
  setIsFirmwareUploadSuccessful: PropTypes.func.isRequired,
  firmwareUploadSuccessMessage: PropTypes.string.isRequired,
  setFirmwareUploadSuccessMessage: PropTypes.func.isRequired,
  isFirmwareUploadFailed: PropTypes.bool.isRequired,
  setIsFirmwareUploadFailed: PropTypes.func.isRequired,
  firmwareUploadFailedMessage: PropTypes.string.isRequired,
  setFirmwareUploadFailedMessage: PropTypes.func.isRequired,
  isWebSerialPortOpen: PropTypes.bool.isRequired,
  selectedCheckMcuidFirmwareSet: PropTypes.object,
  targetUploadOrder: PropTypes.number,
  setTargetUploadOrder: PropTypes.func,
  isSingleMcuid: PropTypes.bool,
  isMcuidChecked: PropTypes.bool,
  setIsMcuidChecked: PropTypes.func,
  setIsMcuidFirmwareUploaded: PropTypes.func,
  setIsMcuidTestingDisabled: PropTypes.func
};