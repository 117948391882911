import { useCountDown } from 'ahooks';
import React, { useEffect, useState } from 'react';
import styles from '../styles/firmware-test.module.scss';
import {
  API_PATH_CHECK_MCUID,
  API_PATH_POST_APP_TEST_RESULTS,
  API_PATH_POST_SOM_TEST_RESULTS,
  BAUD_RATE,
  COUNTDOWN_AUTO_SUBMIT_TEST_RESULT,
  TESTING_TYPE
} from "../util/constants";
import { apiRequest } from "../util/util";

import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import { Alert, AlertTitle } from '@material-ui/lab';
import PropTypes from "prop-types";
import BarLoader from "react-spinners/BarLoader";
import { useAuth } from "./../util/auth";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },

}));

const convertCharCodeToString = (array) => {
  let buffer = "";

  for (let i = 0; i < array.length; i++) {
    buffer += String.fromCharCode(array[i]);
  }
  return buffer;
};

export default function FirmwareTest({
  testingInputPackageId,
  testingInputAbb,
  testingInputCommand,
  testingInputVersion,
  testingInputImageUrl,
  testingInputTimeoutSeconds,
  isTestingFirmware,
  setIsTestingFirmware,
  isFirmwareTestingCompleted,
  setIsFirmwareTestingCompleted,
  setIsWebSerialPortOpen,
  testingType,
  setTestingInputMcuid,
  setTestingInputSomIds,
  testingInputMcuid,
  testingInputSomIds,
  isUploadingFirmware,
  setIsMcuidChecked,
  isMcuidFirmwareUploaded,
  isMcuidTestingDisabled
}) {
  const auth = useAuth();

  const classes = useStyles();

  const [isWebSerialAvailable, setIsWebSerialAvailable] = useState(false);

  const [connectedPort, setConnectedPort] = useState(null);
  const [isConnectingPort, setIsConnectingPort] = useState(false);
  const [isPortConnected, setIsPortConnected] = useState(false);
  const [connectedPortReader, setConnectedPortReader] = useState(null);
  const [isPortOpen, setIsPortOpen] = useState(false);
  const [isPortListening, setIsPortListening] = useState(false);

  const [mcuidReceived, setMcuidReceived] = useState(null);
  const [testDetailsReceived, setTestDetailsReceived] = useState(null);
  const [isPayloadReceivedFromHardware, setIsPayloadReceivedFromHardware] = useState(false);
  const [isTestFailed, setIsTestFailed] = useState(false);
  const [isMcuidValid, setIsMcuidValid] = useState(false);
  const [mcuidChecked, setMcuidChecked] = useState(null);
  const [checkMcuidMessage, setCheckMcuidMessage] = useState("");

  const [testResultToBeSubmitted, setTestResultToBeSubmitted] = useState(null);
  const [isTestResultSubmitted, setIsTestResultSubmitted] = useState(false);
  const [isSubmittingTestResult, setIsSubmittingTestResult] = useState(false);

  const [inputTestRemarks, setInputTestRemarks] = useState("");

  const [countdownAutoSubmitLeftTime, setCountdownAutoSubmitLeftTime] = useState(0);
  const [countdownTestingLeftTime, setCountdownTestingLeftTime] = useState(0);
  const [isTestingTimeOut, setIsTestingTimeOut] = useState(false);
  
  const [somId, setSomId] = useState(null);

  /* Check that Web Serial API is available on the browser. */

  useEffect(() => {
    if (navigator.serial) {
      console.log("[INFO] Web Serial API is available.");
      setIsWebSerialAvailable(true);
    } else {
      console.log("[ERROR] - Web Serial API is unavailable.");
      setIsWebSerialAvailable(false);
    }
  }, []);


  const submitTestResult = () => {
    setCountdownAutoSubmitLeftTime(0);
    setIsTestResultSubmitted(false);
    setIsSubmittingTestResult(true);

    let apiPath;
    switch(testingType) {
      case TESTING_TYPE.SOM:
        apiPath = API_PATH_POST_SOM_TEST_RESULTS;
        break;
      case TESTING_TYPE.APP:
        apiPath = API_PATH_POST_APP_TEST_RESULTS;
        break;
      default:
        console.log("[ERROR] Invalid testing type.");
        return;
    }

    apiRequest(apiPath, "POST", testResultToBeSubmitted, "")
      .then((data) => {
        if (data.success && data.success === true) {
          setIsSubmittingTestResult(false);
          setIsTestResultSubmitted(true);
          setIsFirmwareTestingCompleted(true);
          console.log(`[INFO] Test result payload sent: ${JSON.stringify(testResultToBeSubmitted)}`);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsSubmittingTestResult(false);
        setIsTestResultSubmitted(false);
        setIsFirmwareTestingCompleted(false);
      });
  };

  const autoSubmitTestResult = () => {
    console.log("[INFO] Auto submitting test result...");
    submitTestResult();
  };

  const [, formattedResult] = useCountDown({
    leftTime: countdownAutoSubmitLeftTime,
    onEnd: () => {
      autoSubmitTestResult();
    },
  });
  const { seconds } = formattedResult;


  const timeOutTesting = () => {
    if (!isPayloadReceivedFromHardware) {
      console.log("[INFO] Testing timed out, no payload received from hardware.");
      setIsTestingTimeOut(true);
      setIsTestingFirmware(false);
      setIsFirmwareTestingCompleted(true);
      setCountdownTestingLeftTime(0);
    }
  };

  useCountDown({
    leftTime: countdownTestingLeftTime,
    onEnd: () => {
      timeOutTesting();
    },
  });

  /* Web serial port operations. */

  const handleRequestPort = () => {
    setIsConnectingPort(true);
    navigator.serial
      .requestPort()
      .then((port) => {
        setConnectedPort(port);
        setIsPortConnected(true);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handleOpenPort = async () => {
    if (connectedPort) {
      console.log("[INFO] Opening port...");
      await connectedPort.open({ baudRate: BAUD_RATE });
      setIsPortOpen(true);
    }
  };

  useEffect(() => {
    if (connectedPort) {
      handleOpenPort();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connectedPort]);

  const processSOMTestResult = (parsedResponse) => {
    const { mcuid, test_details } = parsedResponse;

    // Due to legacy firmware, the key is either test_result or test_pass.
    const receivedTestPass = parsedResponse.test_result !== undefined ? parsedResponse.test_result : parsedResponse.test_pass;

    const testResultPayload = {
      package: { id: testingInputPackageId },
      mcuid: mcuid,
      test_details: JSON.stringify(test_details),
      test_pass: receivedTestPass,
      remark: ""
    };

    if (receivedTestPass === 0) {
      setIsTestFailed(true);
    }

    setCountdownAutoSubmitLeftTime(COUNTDOWN_AUTO_SUBMIT_TEST_RESULT);
    setMcuidReceived(mcuid);
    setTestDetailsReceived(test_details);
    setTestResultToBeSubmitted(testResultPayload);
    setIsTestingFirmware(false);
  }

  const processAppTestResult = (parsedResponse) => {
    const { mcuid, test_details } = parsedResponse;

    // Due to legacy firmware, the key is either test_result or test_pass.
    const receivedTestPass = parsedResponse.test_result !== undefined ? parsedResponse.test_result : parsedResponse.test_pass;

    const testResultPayload = {
      som: testingInputSomIds.map(id => ({ id })),
      app_board_serial: testingInputMcuid.substring(0, 6),
      test_pass: receivedTestPass,
      test_details: JSON.stringify(test_details),
      remark: ""
    };

    if (receivedTestPass === 0) {
      setIsTestFailed(true);
    }

    setCountdownAutoSubmitLeftTime(COUNTDOWN_AUTO_SUBMIT_TEST_RESULT);
    setMcuidReceived(mcuid);
    setTestDetailsReceived(test_details);
    setTestResultToBeSubmitted(testResultPayload);
    setIsTestingFirmware(false);
  }

  const processCheckMcuidTestResult = (parsedResponse) => {
    const { mcuid } = parsedResponse;
    setMcuidChecked(mcuid);

    if (testingInputMcuid === null) {
      setTestingInputMcuid(mcuid);
    }

    apiRequest(API_PATH_CHECK_MCUID, "GET", null, mcuid)
      .then((data) => {
        if (data.code && data.code === "token_not_valid") {
          console.log(`[ERROR] ${data.code}`)
          auth.signout();
          return;
        }

        if (data.available && data.available === true) {
          console.log(data.message);
          setIsMcuidValid(true);
          setIsMcuidChecked(true);
          setCheckMcuidMessage(data.message);
        } else {
          console.log(data.message);
          setIsMcuidValid(false);
          setIsMcuidChecked(false);
          setCheckMcuidMessage(data.message);
        }

        if (data.som_id) {
          setSomId(data.som_id);
          setTestingInputSomIds(prevIds => [...prevIds, data.som_id]);
        }

        setIsTestingFirmware(false);
      })
      .catch( (err) => {
        console.log(err);
        setIsTestingFirmware(false);
      });
  }

  const handleListen = async () => {
    if (connectedPort) {
      const reader = connectedPort.readable.getReader();
      setConnectedPortReader(reader);

      // Listen to data coming from the serial device.
      setIsPortListening(true);
      setIsConnectingPort(false);
      setIsWebSerialPortOpen(true);

      let buffer = [];
      let isFragmented = false;

      try {
        while (true) {
          const { value, done } = await reader.read();
          const lastChar = value[value.length - 1];

          // https://ascii.cl/
          // ASCII 10 is new line character: LF (Line Feed) or \n
          if (lastChar !== 10) {
            // initial or intermediate fragment detected
            console.log("[INFO] Fragment detected in serial buffer.");
            isFragmented = true;
            buffer = [...buffer, ...value];
          } else if (lastChar === 10 && isFragmented) {
            // final fragment detected
            console.log("[INFO] Final fragment detected in serial buffer.");
            buffer = [...buffer, ...value];

            const response = convertCharCodeToString(buffer);

            try {
              const parsedResponse = JSON.parse(response);
              console.log(`[SUCCESS] Payload received: ${JSON.stringify(parsedResponse)}`);
              setIsPayloadReceivedFromHardware(true);

              if (testingType === TESTING_TYPE.SOM) {
                console.log("[INFO] Processing SOM test result.");
                processSOMTestResult(parsedResponse);
              }

              if (testingType === TESTING_TYPE.APP) {
                console.log("[INFO] Processing APP test result.");
                processAppTestResult(parsedResponse);
              }

              if (testingType === TESTING_TYPE.CHECK_MCUID) {
                console.log("[INFO] Processing CHECK_MCUID test result.");
                processCheckMcuidTestResult(parsedResponse);
              }

            } catch (error) {
              console.log("[ERROR] - Unable to parse response.");
              console.log(error.message);
              console.log(`[ERROR] - Received response: ${response}`);
            }

            // reset
            isFragmented = false;
            buffer.length = 0;
          } else {
            // no fragment
            const response = convertCharCodeToString(value);

            try {
              const parsedResponse = JSON.parse(response);
              console.log("[INFO] No fragment in serial buffer.");
              console.log(`[SUCCESS] Payload received: ${JSON.stringify(parsedResponse)}`);
              setIsPayloadReceivedFromHardware(true);

              if (testingType === TESTING_TYPE.SOM) {
                console.log("[INFO] Processing SOM test result.");
                processSOMTestResult(parsedResponse);
              }

              if (testingType === TESTING_TYPE.APP) {
                console.log("[INFO] Processing APP test result.");
                processAppTestResult(parsedResponse);
              }

              if (testingType === TESTING_TYPE.CHECK_MCUID) {
                console.log("[INFO] Processing CHECK_MCUID test result.");
                processCheckMcuidTestResult(parsedResponse);
              }
  
            } catch (error) {
              console.log("[ERROR] - Unable to parse response.");
              console.log(error.message);
              console.log(`[ERROR] - Received response: ${response}`);
            }
          }

          if (done) {
            // Allow the serial port to be closed later.
            reader.releaseLock();
            setIsPortListening(false);
            break;
          }
        }
      } catch (error) {
        // https://developer.chrome.com/en/articles/serial/#close-port
        // calling reader.cancel() will force reader.read() to resolve immediately with { value: undefined, done: true } and therefore allowing the loop to call reader.releaseLock()
        if (error.message === "Cannot read properties of undefined (reading 'length')") {
          console.log("[INFO] Reader is cancelled.");
        } else {
          console.log(error.message);
        }
      } finally {
        // Allow the serial port to be closed later.
        console.log("[INFO] Releasing lock on reader.");
        reader.releaseLock();
        setIsPortListening(false);
      }

    } else {
      console.log("[ERROR] - No connected port.");
    }
  };

  useEffect(() => {
    if (isPortOpen) {
      // wait for 1 second before listening to the port
      // this is to prevent the UI from prematurely showing up before the port is ready to be listened to
      setTimeout(() => {
        handleListen();
      }, 1000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPortOpen]);

  const writeToSerialPort = async (payload) => {

    const writer = connectedPort.writable.getWriter();

    const jsonPayload = JSON.stringify(payload);

    const string = `${jsonPayload}\r`;
    const uint8Array = new Uint8Array(string.length);

    for (let i = 0; i < string.length; i++) {
      uint8Array[i] = string.charCodeAt(i);
    }

    const data = uint8Array;
    await writer.write(data);
    console.log(`[INFO] Payload sent: ${jsonPayload}`);

    // Allow the serial port to be closed later.
    writer.releaseLock();
  };

  const getRtcTimestamp = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0');
    const day = String(now.getDate()).padStart(2, '0');
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const seconds = String(now.getSeconds()).padStart(2, '0');

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }

  const handleSendCommand = () => {
    let payload;

    switch (testingType) {
      case TESTING_TYPE.SOM:
        payload = {
          abb: testingInputAbb,
          version: testingInputVersion,
          command: testingInputCommand
        };
        break;
      case TESTING_TYPE.CHECK_MCUID:
        const inputRtc = getRtcTimestamp();
        payload = {
          abb: testingInputAbb,
          command: testingInputCommand,
          rtc: inputRtc
        }
        break;
      default:
        payload = {
          abb: testingInputAbb,
          version: testingInputVersion,
          command: testingInputCommand
        };
        break;
    }

    setIsTestingFirmware(true);
    setIsTestingTimeOut(false);
    setCountdownTestingLeftTime(testingInputTimeoutSeconds * 1000);
    writeToSerialPort(payload);
  };

  const handleDisconnect = async () => {
    if (connectedPort && connectedPortReader) {
      console.log("[INFO] Cancelling reader...");
      await connectedPortReader.cancel();
      console.log("[INFO] Closing port...");
      await connectedPort.close();

      setIsWebSerialPortOpen(false);

      // reset all states
      setConnectedPort(null);
      setIsPortConnected(false);
      setConnectedPortReader(null);
      setIsPortOpen(false);
      setIsPortListening(false);
      setTestDetailsReceived(null);
      setIsPayloadReceivedFromHardware(false);
      setMcuidReceived(null);
      setTestResultToBeSubmitted(null);
      setIsTestResultSubmitted(false);
      setIsTestFailed(false);
    }
  };

  useEffect(() => {
    if (isTestFailed && testResultToBeSubmitted) {
      submitTestResult();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTestFailed, testResultToBeSubmitted]);


  const handleUpdateTestRemarks = (event) => {
    setInputTestRemarks(event.target.value);

    setTestResultToBeSubmitted(prevState => ({
      ...prevState,
      remark: event.target.value
    }));
  };

  let chipLabel;
  switch (testingType) {
    case TESTING_TYPE.SOM:
      chipLabel = "SOM Testing"
      break;
    case TESTING_TYPE.APP:
      chipLabel = "Application testing"
      break;
    case TESTING_TYPE.CHECK_MCUID:
      chipLabel = "Check MCUID"
      break;
    default:
      console.log("[ERROR] Invalid upload type");
      break;
  }

  return (
    <section className={styles.mainSection}
    style={((testingType === TESTING_TYPE.CHECK_MCUID && !isMcuidFirmwareUploaded) || 
            isMcuidTestingDisabled) ? 
      { opacity: 0.5, pointerEvents: "none"} : {} }
    >
      <Paper className={classes.paper}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
          <Chip 
            label={chipLabel}
            variant="outlined"  
            color="primary"
            />
            {!isWebSerialAvailable && <h3>⚠️ Please use Google Chrome browser.</h3>}
            {
              isWebSerialAvailable &&
              <>
                <p>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleRequestPort}
                    disabled={isPortConnected || isFirmwareTestingCompleted || isUploadingFirmware}
                    className={styles.connectToSerialPortButton}
                  >
                    Connect to Serial Port
                  </Button>
                  {
                    isConnectingPort && !isPortListening &&
                    <BarLoader
                      color="#36d7b7"
                      width="100%"
                    />
                  }
                </p>
                <p>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={handleDisconnect}
                    disabled={isSubmittingTestResult || !isPortListening || !connectedPort || !connectedPortReader}
                  >
                    Disconnect from Serial Port
                  </Button>
                </p>
                <p>Connection: {connectedPort ? "✅" : "🚫"}</p>

                {isPortListening && (
                  <>
                    <section>
                      <section>
                        {
                          testingType === TESTING_TYPE.SOM &&
                          <section className={styles.pcbaImageSection}>
                            <div className={styles.pcbaImageContainer}>
                              <img src={testingInputImageUrl ? testingInputImageUrl : `https://via.placeholder.com/400/cccccc/000000?text=PCBA Testing`} alt="" />
                            </div>
                          </section>
                        }
                        <Alert severity="info" className={styles.alert}>
                          Please attach all relevant connections to the DUT and click&nbsp;
                          {(testingType === TESTING_TYPE.SOM || testingType === TESTING_TYPE.APP) && "TEST" }
                          {testingType === TESTING_TYPE.CHECK_MCUID && "CHECK MCUID"}.
                        </Alert>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={handleSendCommand}
                          disabled={isTestingFirmware || isPayloadReceivedFromHardware}
                          className={styles.testButton}
                        >
                          {(testingType === TESTING_TYPE.SOM || testingType === TESTING_TYPE.APP) && "TEST" }
                          {testingType === TESTING_TYPE.CHECK_MCUID && "CHECK MCUID"}
                        </Button>
                        {
                          isTestingFirmware &&
                          <BarLoader
                            color="#36d7b7"
                            width="100%"
                          />
                        }
                        {
                          isTestingTimeOut &&
                          <Alert severity="error" className={styles.alert}>
                            Time out after {testingInputTimeoutSeconds} seconds. No response received from hardware.
                          </Alert>
                        }
                      </section>
                      {
                        isPayloadReceivedFromHardware && 
                        (testingType === TESTING_TYPE.SOM || testingType === TESTING_TYPE.APP) &&
                        <>
                          <Divider variant="middle" />
                          <section className={styles.testDetails}>
                            <h3>Test details</h3>
                            {
                              mcuidReceived &&
                              <>
                                <h4>MCUID full: {mcuidReceived}</h4>
                                <h2 className={styles.mcuidShort}>MCUID short: {mcuidReceived.slice(0, 6)}</h2>
                              </>
                            }
                            <ul>
                              {
                                testDetailsReceived && testDetailsReceived.map(t => (
                                  <li key={t.name}>
                                    {t.result === "FAIL" ? "🔴" : "🟢"} {t.name}
                                    {t.result === "FAIL" ? (t.reason ? ` -- reason: ${t.reason}` : "") : ""}
                                  </li>
                                ))
                              }
                            </ul>
                            <TextField
                              id="filled-multiline-flexible"
                              label="(Optional) Test remarks"
                              multiline
                              maxRows={4}
                              value={inputTestRemarks}
                              onChange={handleUpdateTestRemarks}
                              placeholder='Enter test remarks...'
                              variant="outlined"
                              fullWidth
                              className={styles.testRemarksInput}
                              disabled={isSubmittingTestResult || isTestResultSubmitted || isTestFailed}
                            />
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={submitTestResult}
                              disabled={isSubmittingTestResult || isTestResultSubmitted || isTestFailed}
                              className={styles.submitTestResultButton}
                            >
                              Submit test result
                            </Button>
                          </section>
                        </>
                      }
                      {
                        isSubmittingTestResult &&
                        <BarLoader
                          color="#36d7b7"
                          width="100%"
                        />
                      }
                      {
                        isTestResultSubmitted && <Alert severity="success">Test result submitted.</Alert>
                      }
                      {
                        isPayloadReceivedFromHardware && testingType === TESTING_TYPE.CHECK_MCUID && !isTestingFirmware &&
                        (
                          isMcuidValid 
                        ?
                        <Alert severity="success">
                          <AlertTitle>MCUID: {mcuidChecked}</AlertTitle>
                          <p>
                            {checkMcuidMessage}
                          </p>
                          {
                            somId && <p>SOM ID: {somId}</p>
                          }
                        </Alert>
                        :
                        <Alert severity="error">
                          <AlertTitle>MCUID: {mcuidChecked}</AlertTitle>
                          {checkMcuidMessage}
                        </Alert>
                        )
                      }
                      {
                        countdownAutoSubmitLeftTime > 0 &&
                        <Alert severity="info" className={styles.alert}>
                          Auto submitting test result in {seconds}s.
                        </Alert>
                      }
                    </section>
                  </>
                )}
              </>
            }
          </Grid>
        </Grid>
      </Paper>

    </section>
  );
}

FirmwareTest.propTypes = {
  testingType: PropTypes.string.isRequired,
  testingInputPackageId: PropTypes.oneOfType([PropTypes.number.isRequired, PropTypes.oneOf([null])]),
  testingInputAbb: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.oneOf([null])]),
  testingInputCommand: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.oneOf([null])]),
  testingInputVersion: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.oneOf([null])]),
  testingInputImageUrl: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.oneOf([null])]),
  testingInputTimeoutSeconds: PropTypes.oneOfType([PropTypes.number.isRequired, PropTypes.oneOf([null])]),
  isTestingFirmware: PropTypes.bool.isRequired,
  setIsTestingFirmware: PropTypes.func.isRequired,
  isFirmwareTestingCompleted: PropTypes.bool.isRequired,
  setIsFirmwareTestingCompleted: PropTypes.func.isRequired,
  setIsWebSerialPortOpen: PropTypes.func.isRequired,
  setTestingInputMcuid: PropTypes.func,
  setTestingInputSomIds: PropTypes.func,
  testingInputMcuid: PropTypes.string,
  testingInputSomIds: PropTypes.array,
  isUploadingFirmware: PropTypes.oneOfType([PropTypes.bool.isRequired, PropTypes.oneOf([null])]),
  setIsMcuidChecked: PropTypes.func,
  isMcuidFirmwareUploaded: PropTypes.bool,
  isMcuidTestingDisabled: PropTypes.bool
};