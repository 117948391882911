import React, { useEffect } from "react";
import { useAuth } from '../util/auth';
import AuthSection from "./../components/AuthSection";
import Meta from "./../components/Meta";
import { useRouter } from "./../util/router";

function AuthPage(props) {
  const router = useRouter();
  const auth = useAuth();

  useEffect(() => {
    if (auth.user) {
      router.push(router.query.next || "/dashboard");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth]);

  return (
    <>
      <Meta title="Auth" />
      <AuthSection
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        type={router.query.type}
        afterAuthPath={router.query.next || "/dashboard"}
      />
    </>
  );
}

export default AuthPage;
