import Container from "@material-ui/core/Container";
import React from "react";
import Auth from "./Auth";
import Section from "./Section";
import SectionHeader from "./SectionHeader";

function AuthSection({
  type,
  bgColor,
  size,
  bgImage,
  bgImageOpacity,
  afterAuthPath
}) {

  return (
    <Section
      bgColor={bgColor}
      size={size}
      bgImage={bgImage}
      bgImageOpacity={bgImageOpacity}
    >
      <Container maxWidth="xs">
        <SectionHeader
          title="Welcome"
          subtitle=""
          size={4}
          textAlign="center"
        />
        <Auth
          type={type}
          buttonAction="Sign in"
          afterAuthPath={afterAuthPath}
          key={type}
        />
      </Container>
    </Section>
  );
}

export default AuthSection;
